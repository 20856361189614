

import React from 'react';
import { connect } from "react-redux";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import qs from 'querystring';


import store from '../store/index';
import { authenticationFail, refreshTokenSuccess } from '../actions/index';
import { CodeHighlighter } from '../components/CodeHighlighter';
import { getAPIAxios } from '../API';



const serviceEndpoint = "refresh-token";

export const serviceSample = {}


export function RefreshToken() {
  return <RefreshTokenConnectedComponent/>;
}


class RefreshTokenComponent extends React.Component {

  state = {
    refreshToken: '',
    response: null,
    error: null,
    working: false,
  }

  componentDidMount = () => {
    this.setState({ refreshToken: store.getState().refreshToken ? store.getState().refreshToken : ''});
  }

  render() {

        return (
            <div>
                  <h3>RefreshToken</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to refresh the token. To automatically fill in the token, login first.
                  </p>

                  <Form.Group>
                        <Form.Label><b>refresh_token</b></Form.Label>
                        <Form.Control type="text" value={this.state.refreshToken} name="refresh_token" onChange={ this.updateArgument } />
                      </Form.Group>

                  <div className="formActions">
                    <Button variant="primary" type="button" onClick={ this.runService }>
                      Refresh Token
                    </Button>
                  </div>

                 { this.showResponseCode('Response', this.state.response) }
                 { this.showWorking() }
                 { this.showError() }

                  <h4>Request</h4>
                  <p>
                    A POST with the refresh_token as parameter.<br/>
                    This is a simple application/x-www-form-urlencoded form.
                  </p>

                  <h4>Response</h4>
                  <p>
                    A JSON with the new token or an autorization denied.
                  </p>

            </div>
        );
  }


  runService = () => {

    this.setState({ error: null });
    const aAxios = getAPIAxios();
    
    this.setState({ working: true,
                    error: null,
                    response: null
                  });

                  console.log("Posting axios ",this.state.refreshToken);
    aAxios.post( serviceEndpoint, qs.stringify({
            refresh_token: this.state.refreshToken
          }), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })  
          .then( (response) => {
              this.setState({ response: response.data });
                this.props.refreshTokenSuccess(response.data.token);
          })
          .catch( (error) => {
                console.log("An error has occurred!", error.response);
                let details = '';
                if (typeof error.response !== 'undefined' &&
                    typeof error.response.statusText !== 'undefined') {
                  details = ' ' + error.response.statusText;
                }
                this.setState({ error: '' + error + details });
          })
          .finally( () => {
              this.setState({ working: false });
          });
  }

  updateArgument = (e) => {
    this.setState({ refreshToken: e.target.value });
  }


  showWorking = () => {
    if (this.state.working) {
        return <div className="d-flex justify-content-center"><Spinner  style={{ marginTop: '1rem'}}  animation="grow" /></div>
    }

    return null;
  }

  showError = () => {
    if (this.state.error) {
        if (Array.isArray(this.state.error)) {
            const errors = this.state.error.map( (a, k) => <li key={k}>{a}</li>);
            return  <Alert variant="danger" style={{ marginTop: '1rem'}} >{ errors }</Alert>
        }
        return <Alert variant="danger" style={{ marginTop: '1rem'}} >{ this.state.error }</Alert>
    }

    return null;
  }

  showResponseCode = (title, code) => {

      if (!code) {
        return null;
      }

      return <div style={{ marginTop: '1rem', overflow: 'scroll', maxHeight: 500 }}>
                  <p>{ title }</p>
                  <CodeHighlighter code={code} />
                  
             </div>
  }
} 

const mapStateToProps = state => {
  return { 
            refreshToken: state.authToken,
            username: state.username,
         };
};


function mapDispatchToProps(dispatch) {
  return {
    authenticationFail: (error) => dispatch(authenticationFail(error)),
    refreshTokenSuccess: (authToken) => dispatch(refreshTokenSuccess(authToken)),
  };
}

const RefreshTokenConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(RefreshTokenComponent);