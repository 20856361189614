

import React from 'react';
import { CodeHighlighter } from '../components/CodeHighlighter';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/player/%nid";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Player ID'},
]

const serviceSample = {}


export function PlayerDetails() {
  return <PlayerDetailsComponent/>;
}


class PlayerDetailsComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>View a Player</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the detailed info about a Player<br/>
                    If the service is executed as Player (and the pid specified is not the one of the Player itself) the info of each player is limited to <i>pid</i>, <i>firstname</i>, <i>lastname</i>, <i>primary</i> and <i>secondary role</i>, <i>shirt number</i>.<br/>
                    In other words Players can see just few information of their teammates, but can see their full profile.
                  </p>
                  <p>
                    An example of the returned JSON:
                  </p>
                  <CodeHighlighter code={ {
  "pid": "2855",
  "firstname": "Diego",
  "lastname": "Lopez",
  "display_shirt_num": false,
  "shirt_num": null,
  "primary_role_id": "1",
  "primary_role": "Portiere",
  "secondary_role_id": "0",
  "secondary_role": "(Nessun ruolo specificato)",
  "email": "player2@saysource.com",
  "telephone1": "+34 638671889",
  "telephone2": "",
  "telephone3": "",
  "birthyear": "1978",
  "birthdate": "07/01/1978",
  "height": "173",
  "weight": "64",
  "foot_type_id": "1",
  "foot_type": "Piede destro",
  "nationality": "Argentina",
  "nationality_iso2": "ar",
  "federation_id": "A0012938492",
  "address": "Avn El Madroñal 67 bajo 69",
  "city": "Costa Adeje",
  "is_active": true,
  "is_guest": false
} }/>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the generic event details.
                  </p>
                  <p>
                  </p>
                  
            </div>
        );
  }


} 