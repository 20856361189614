

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { ArgumentsTable } from '../components/ArgumentsTable';
// import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "user/add-device-token";
const serviceArguments = [
  { name: 'token', type: 'text',  mandatory: true, default: null, description: 'Device Token'}
];

const serviceUrlArguments = [];

const serviceSample = {
  'token'        : 'XYZ12340823423094-23482',
  'os'           : 'iOS',
}


export function UserAddDeviceToken() {
  return <UserAddDeviceTokenComponent/>;
}


class UserAddDeviceTokenComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Add a Device Token for the current user</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to add a device token to the current user
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={false}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with a success message o an error object
                  </p>
                  <ArgumentsTable args={serviceArguments} />
                  
                  <h4>Response</h4>
                  <p>
                    A json object with the following two possible keys:<br/>
                    <b>errors</b>: map of errors indexed by field<br/>
                    <b>message</b>: ok.
                  </p>
                  
            </div>
        );
  }
} 