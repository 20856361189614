import React from 'react';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import './prism.css';


export function CodeHighlighter({code, useScroll}) {

  let content = <span style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>{ code }</span>;
  
  if (typeof code === 'object') {
      content = <pre
                dangerouslySetInnerHTML={ { __html: highlight( JSON.stringify(code, null, 2) , languages.json) } }
            />
  }
  

  return <div className={ "jsonSample" + (useScroll ? " useScroll" : "") }>
              { content }
          </div>
}