

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const seasonsEndpoint = "seasons?perms=%perms";

const seasonsArguments = []

const serviceUrlArguments = [
  { name: 'perms', type: 'text',  mandatory: false, default: '', description: 'Set it to true to get the list of staff or player permissions for this user in the context of each season'}
]

const seasonsSample = {}


export function Seasons() {
  return <SeasonsComponent/>;
}


class SeasonsComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Seasons</h3>

                  <p>Endpoint: <b>{seasonsEndpoint}</b></p>

                  <p>
                    This service can be used to get the list of teams/seasons to which the user has access to, either as owner, staff member or player.<br/>
                    An example season JSON:
                  </p>
                  <CodeHighlighter code={ {
                    "sid": "4287",
                    "archived": false,
                    "label": "Incubator",
                    "cid": 1234,
                    "club_label": "Giulio Toffoli's Club",
                    "role": "owner",
                    "role_label": "Onwer",
                    "date_start": 0,
                    "date_end": 0,
                    "is_incubator": false,
                    "fixed_num": false
                  }
                  }/>


                  <ServiceForm
                        args={seasonsArguments}
                        urlArgs={serviceUrlArguments}
                        sample={seasonsSample}
                        endpoint={seasonsEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of seasons.
                  </p>
                  
                  <p>
                    <i>role</i> can be "owner", "staff member" or "player".<br/>
                    If the user is a player, an extra field called "pid" is added to the response. It contains the Player ID of this user
                    in the context of that season.  
                  </p>

            </div>
        );
  }


} 