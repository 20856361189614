

import React from 'react';
import { ArgumentsTable } from '../components/ArgumentsTable';
import ServiceForm from '../components/ServiceForm';


const matchOpponentPlayerEditEndpoint = "season/%sid/match/%mid/edit-opponent-player/%pid";
const matchOpponentPlayerEditArguments = [
  { name: 'lastname', type: 'string',  mandatory: false, default: '', description: 'Player lastname'},
  { name: 'firstname',  type: 'string',  mandatory: false, default: '', description: 'Player firstname'},
  { name: 'primary_role_id',  type: 'string',  mandatory: false, default: '', description: 'One of the role id (see player roles)'},
  { name: 'birthyear',  type: 'integer',  mandatory: false, default: '', description: 'Birth year'},
  { name: 'captain',   type: 'boolean', mandatory: false, default: false, description: 'If true, the player is set as captain'},
  { name: 'vice_captain',   type: 'boolean', mandatory: false, default: false, description: 'If true, the player is set as vice captain'},
];

const matchOpponentPlayerEditUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'mid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
  { name: 'pid', type: 'string',  mandatory: true, default: '', description: 'Opponent Player ID'},
]

const matchOpponentPlayerEditSample = {
  'lastname': 'Cannavaro',
  'firstname': 'Fabio',
  'primary_role_id': '3',
  'birthyear': 1973,
  'vice_captain': true
}


export function MatchOpponentPlayerEdit() {
  return <MatchOpponentPlayerEditComponent/>;
}


class MatchOpponentPlayerEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Edit an Opponent player</h3>

                  <p>Endpoint: <b>{matchOpponentPlayerEditEndpoint}</b></p>

                  <p>
                    This service can be used to modify an opponent player.<br/>
                    <br/>
                    Lastname and Firstname cannot be both empty, please specify a value for at least one of the two.
                    <br/>
                    The the field "id" is specified in the json, it will be reset to the one specified in the url.<br/>
                    <br/>
                    Please note that additional fields specified in the json are stored as part of the player object, but are not garanteed to be kept in case of player change with the web app.
                  </p>
                  
                  <ServiceForm
                        args={matchOpponentPlayerEditArguments}
                        urlArgs={matchOpponentPlayerEditUrlArguments}
                        sample={matchOpponentPlayerEditSample}
                        endpoint={matchOpponentPlayerEditEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with the event data
                  </p>
                  <ArgumentsTable args={matchOpponentPlayerEditArguments} />
                  
                  <h4>Response</h4>
                  <p>
                    The whole opponent formation
                  </p>
                  
            </div>
        );
  }


} 