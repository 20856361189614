

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "season/%sid/calendar";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
]

const serviceSample = {}


export function Calendar() {
  return <CalendarComponent/>;
}


class CalendarComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Calendar (events)</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the list of events in calendar. It is a shortcut to get the
                    aggregated results of <i>matches</i>, <i>training_sessions</i> and <i>generic event</i>.<br/>
                    The result is in form of:
                  </p>
                  <CodeHighlighter code={ {
                      "matches": [],
                      "training_sessions": [],
                      "generic_events": []
                    }
                  }/>


                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of service.
                  </p>
                  
            </div>
        );
  }


} 