

import React from 'react';
import { connect } from "react-redux";
import Form from 'react-bootstrap/Form';

import { updateUrl } from '../actions';


export function Introduction() {
    return <IntroductionComponentConnected />;
}

class IntroductionComponent extends React.Component {

  render() {
      return (
          <div>
            <h3>Introduction</h3>
            <p>The YouCoachApp REST API is used to support external application such as the YouCoachApp Mobile app.
            </p>
            <p>
              The communication with the endpoint needs to be performed over <b>HTTPS</b>.<br/>
              For development purposes, HTTPS is not a strict requirement.<br/>
            </p>

            <h4>API Setup</h4>

            <Form>
                  <Form.Group>
                    <Form.Label>REST API Base Url</Form.Label>
                    <Form.Control type="text" value={ this.props.baseUrl ? this.props.baseUrl : '' } onChange={ this.updateBaseUrl } />
                    <small style={{ display: 'block', marginTop: 5 }} className="text-muted">This URL will be used in the samples of this documentation, you can change it to match your testing environment.</small>
                  </Form.Group>
            </Form>
                       
            The API offers both public and protected services. Protected services require authentication.<br />
            The authentication is base on JWT (see <a href="https://jwt.io/">https://jwt.io/</a>).

            <br/>
            <br/>
            <h4>Request Payload</h4>

            <p>Each request is performed by sending a JSON payload to the end point by using a <b>POST</b> request.</p>
            <p>
            Calls that does not require any payload, can be performed by using <b>GET</b> requests.</p>

            <p>While we love the expressivness of HTTP protocol with its GET, POST, PUT, DELETE, OPTIONS... commands, we also like
              to be much more explicit and pragmatic, at cost of not being purists in desiging our REST API. Anyway we prefer an
              RCP style API: creation, update and delete of entities (CRUD) are performed with explicit URLs instead of relaying on the HTTP verb.
            </p>

            
            
            <p>
               In the Table of Content are illustrated the available services and their specific arguments to be set in the JSON payload. 
            </p>

            <p>Services marked as <span className="p" style={{ float: 'none', display: 'inline-block' }}>P</span> may have a different response if the user is a Player in the context of the season involved in the request
            (or in general, this is a way to mark services where the fact that a user is a player is kept in consideration as long as its limitations and permissions).</p>


          </div>
      );
  }

  updateBaseUrl = (event) => {
    this.props.updateUrl(event.currentTarget.value);
  }

} 

const mapStateToProps = (state) => {
  console.log("Updaing state ", state);
  return {
    baseUrl : state.baseUrl
  }
}

const mapDispatchToProps = (dispatch) => {
  console.log("Activating mapDispatchToProps on IntroductionComponent...  ");
  return {
    updateUrl: (url) => dispatch(updateUrl(url)), // Username and password are read from the store
  };
}


const IntroductionComponentConnected = connect(mapStateToProps, mapDispatchToProps)(IntroductionComponent);