

import React from 'react';
import { connect } from "react-redux";
import ServiceForm from '../components/ServiceForm';
import { updateUserPassword } from '../actions';

import store from '../store/index';


const loginEndpoint = "login";

const loginArguments = [
  { name: 'username', type: 'string',  mandatory: true, default: '', description: 'Username or email of the user'},
  { name: 'password', type: 'string',  mandatory: true, default: '', description: 'Password of the user'},
]

// export const loginSample = {
//   username: store.getState().username,
//   password: store.getState().password
// }


export function Login() {
  return <LoginComponentConnected/>;
}


class LoginComponent extends React.Component {

  render() {

        const loginSample = {
          username: store.getState().username,
          password: store.getState().password
        };
        return (
            <div>
                  <h3>Login</h3>

                  <p>Endpoint: <b>{loginEndpoint}</b></p>

                  <p>
                    This service can be used to get the JWT token to be used to access the private services.<br />
                    When the authentication has been completed, the token provided in the JWT object needs to be
                    passed in the request header (Authorization: Bearer &lt;token&gt;).
                  </p>

                  <ServiceForm
                        args={loginArguments}
                        sample={loginSample}
                        endpoint={loginEndpoint}
                        authRequired={false}
                        usePayload={true}
                        isLogin={true}
                        onPayloadChange={ this.onPayloadChange }
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with username and password
                  </p>

                  <h4>Response</h4>
                  <p>
                    A JSON with the JWT object or an autorization denied.
                  </p>

            </div>
        );
  }
  
  onPayloadChange = (str) => {
    const json = JSON.parse(str);
    if (json && typeof json.username !== 'undefined' && json.password !== 'undefined') {
      this.props.updateUserPassword(json.username, json.password);
    }
  }

} 


function mapDispatchToProps(dispatch) {
  return {
    updateUserPassword: (username, password) => dispatch(updateUserPassword(username, password)), // Username and password are read from the store
  };
}

const LoginComponentConnected = connect(null, mapDispatchToProps)(LoginComponent);