

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const meEndpoint = "me";

const meArguments = []

const meSample = {}


export function Me() {
  return <MeComponent/>;
}


class MeComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Me</h3>

                  <p>Endpoint: <b>{meEndpoint}</b></p>

                  <p>
                    This service can be used to get information about the loggedin user.
                  </p>

                  <ServiceForm
                        args={meArguments}
                        sample={meSample}
                        endpoint={meEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the user object
                  </p>

            </div>
        );
  }


} 