

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/training-session/%nid/pdf?complete=%complete&as_json=%as_json";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Training Session ID'},
  { name: 'complete', type: 'boolean',  mandatory: false, default: '0', description: 'Set it to 1 to get the complete version of the training session, 0 for the simple version'},
  { name: 'as_json', type: 'boolean',  mandatory: false, default: '0', description: 'Set it to 1 to get a json response. The PDF will be inside the field data and base64 encoded'},
]

const serviceSample = {}


export function TrainingSessionPdf() {
  return <TrainingSessionPdfComponent/>;
}


class TrainingSessionPdfComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Download a Training Session PDF</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to download the training session PDF.<br/>
                    <br/>
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                        useIframe={true}
                        isPdf={true}
                        responseType={(urlArgs, payload) => urlArgs.as_json === "1" ? "json" : "blob"}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A PDF file.
                  </p>
                  <p>
                  </p>
                  
            </div>
        );
  }


} 