

import React from 'react';
import Table from 'react-bootstrap/Table';


export const ArgumentsTable = ({ args }) => {


  const serviceArgs = args.map( arg => {

    const name = arg.mandatory ? <b>{ arg.name }<sup style={{ color: 'red' }}>*</sup></b> : arg.name;

    return <tr key={arg.name}>
        <td>{ name }</td>
        <td>{ arg.type }</td>
        <td>{ arg.default }</td>
        <td>{ arg.description }</td>
      </tr>
  });

  return <div>
          <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Default</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {  serviceArgs }
              </tbody>
          </Table>
        </div>;

}
