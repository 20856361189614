

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const matchesEndpoint = "season/%sid/matches";

const matchesArguments = []

const matchesUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
]

const matchesSample = {}


export function Matches() {
  return <MatchesComponent/>;
}


class MatchesComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Matches</h3>

                  <p>Endpoint: <b>{matchesEndpoint}</b></p>

                  <p>
                    This service can be used to get the list of matches of a specific season for which the user is either the owner or a staff member.<br/>
                    An example match JSON in the returned array:
                  </p>
                  <CodeHighlighter code={ {
                      "datetime": "25/10/2020 10:30",
                      "datetime_timestamp": 1603618200,
                      "home_game": true,
                      "team1": "<span class=\"team home\">Prima Squadra</span>",
                      "team2": "<span class=\"team \">A.C. Milan</span>",
                      "result": "",
                      "opponent": "A.C. Milan",
                      "number": "1",
                      "city": "Padova",
                      "location": "Stadio Euganeo",
                      "address": "Viale Nereo Rocco, 60",
                      "country": "Italia",
                      "mode": "goals",
                      "mode_label": "Punteggio basato sui gol",
                      "can_edit": true,
                      "can_delete": true,
                      "goals_scored": "1",
                      "goals_conceded": "0",
                      "status": "<span class=\"match-status match-status-0\">Da disputare</span>",
                      "status_id": "0",
                      "match_type": "Campionato",
                      "match_type_name": "Serie A Tim",
                      "match_type_full": "Campionato - Serie A Tim",
                      "nid": "4399"
                    }
                  }/>


                  <ServiceForm
                        args={matchesArguments}
                        urlArgs={matchesUrlArguments}
                        sample={matchesSample}
                        endpoint={matchesEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of matches.
                  </p>
                  
            </div>
        );
  }


} 