

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "articles/list-category/%category?page=%page";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'category', type: 'text',  mandatory: true, default: '', description: 'One of the following: latest, base, agonistica, tattica, preparazione'},
  { name: 'page', type: 'numeric',  mandatory: false, default: '1', description: 'The list is paginated, use page get argument to specify a page'},
]

const serviceSample = {}


export function ArticlesList() {
  return <ArticlesListComponent/>;
}


class ArticlesListComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>List Articles for a specific Category</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the list of article teasers for a specific category. It returns an array of articles.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={false}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with an array of article teasers.
                  </p>
                  
            </div>
        );
  }


} 