

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { ArgumentsTable } from '../components/ArgumentsTable';
// import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "season/%sid/archive";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'}
]

const serviceSample = {}


export function SeasonArchive() {
  return <SeasonArchiveComponent/>;
}


class SeasonArchiveComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Archive a Season</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to archive a season.<br/>
                    Please note that ONLY the season/club owner can archive the season.<br/>
                    In general there is no need to archive a season, but you may still want to do it for one of the following reasons:<br/>
                    <ul>
                      <li>You ran out of teams for your membership. By archiving the season, you will be able to create a new team and still being able to see the information stored in the season you archived (while you still have a valid membership).</li>
                      <li>You want to freeze the information for the season, preventing any further change.</li>
                    </ul>
                    Be aware that by archiving a season, you will no longer be able to edit the season. In particular:
                    <ul>
                      <li>Only the owner of the season will be able to see the season data.</li>
                      <li>Staff Members collaborating with this season will no longer be able to access the season (and if this was the only season with which they were collaborating, they will no longer be able to access YouCoachApp).</li>
                    </ul>
                    <br/>
                    <b>Once archived, only the YouCoach staff will be able to restore your season."</b>
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    The Season ID as url parameter
                  </p>
                  <ArgumentsTable args={serviceArguments} />
                  
                  <h4>Response</h4>
                  <p>
                    The archived season as JSON
                  </p>
            </div>
        );
  }


} 