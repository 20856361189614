

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "articles/search?t=%t&page=%page";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 't', type: 'text',  mandatory: true, default: '', description: 'Text to search'},
  { name: 'page', type: 'numeric',  mandatory: false, default: '1', description: 'The list is paginated, use page GET argument to specify a page. First page (default) is 1.'},
]

const serviceSample = {}


export function ArticlesSearch() {
  return <ArticlesSearchComponent/>;
}


class ArticlesSearchComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Search Articles</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to search for articles. Returns a paginated list (array of articles).
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={false}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with an array of article teasers.
                  </p>
                  
            </div>
        );
  }


} 