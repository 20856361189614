

import React from 'react';
import { CodeHighlighter } from '../components/CodeHighlighter';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/surveys-to-fill?pid=%pid";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'pid', type: 'numeric',  mandatory: false, default: '0', description: 'Player ID. By default is the pid of the team member invoking the service.'}
]

const serviceSample = {}


export function SurveysToFill() {
  return <SurveysToFillComponent/>;
}


class SurveysToFillComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>List of surveys that a player still has to fill</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service is a shortcut to check if a player has still surveys to fill. It is supposed to be invoked by a player in order
                    to display a list of surveys to fill, but it can also be used by a staff member by specifying a pid to check.
                  </p>
                  <p>  
                  If the service is executed by a team member (a player), the value of the <i>pid</i> parameter is ignored.
                  </p>
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of surveys to fill. Here is an example:
                  </p>
                  <CodeHighlighter code={ [
  {
    "event_id": "4709",
    "event_type": "match",
    "event_label": "Admin CLUB - TEST AVVERSARIO",
    "event_date": "14/12/2020 14:00",
    "event_timestamp": 1607950800,
    "weight": 1607950800,
    "survey": "Pre-partita",
    "survey_id": "pre_match"
  },
  {
    "event_id": "4399",
    "event_type": "match",
    "event_label": "Admin CLUB - A.C. Milan",
    "event_date": "25/10/2020 10:30",
    "event_timestamp": 1603618200,
    "weight": 1603618200,
    "survey": "Post-partita",
    "survey_id": "post_match"
  },
  {
    "event_id": "4413",
    "event_type": "training_session",
    "event_label": "Allenamento",
    "event_date": "25/10/2020 10:30",
    "event_timestamp": 1603618200,
    "weight": 1603618200,
    "survey": "Post-allenamento",
    "survey_id": "post_training"
  },
  {
    "event_id": "4413",
    "event_type": "training_session",
    "event_label": "Allenamento",
    "event_date": "25/10/2020 10:30",
    "event_timestamp": 1603618200,
    "weight": 1603618200,
    "survey": "Pre-allenamento",
    "survey_id": "pre_training"
  },
] }/>
                  
            </div>
        );
  }


} 