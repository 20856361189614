

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "drill/%nid/image";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Generic Drill ID'},
]

const serviceSample = {}


export function GenericDrillImage() {
  return <GenericDrillImageComponent/>;
}


class GenericDrillImageComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Custom Drill Image</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to get the image of a generic drill. If the drill is custom, it fallbacks to the Custom Drill image service.<br/>
                    The image returned is actually the cover for the drill and the size for not custom drill cannot be specified.<br/>
                    The service can be used without authentication.
                  </p>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={false}
                        usePayload={false}
                        returnImage={true}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with a single attribute called data, containing a Base64 encode image data URL (like data:image/;base64,....) if the image is found, otherwise 404.
                  </p>

            </div>
        );
  }


} 