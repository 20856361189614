

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/match/%nid/set-vice-captain/%pid";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
  { name: 'pid', type: 'numeric or "none"',  mandatory: true, default: '', description: 'Player ID'},
]

const serviceSample = {}


export function MatchSetViceCaptain() {
  return <MatchSetViceCaptainComponent/>;
}


class MatchSetViceCaptainComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Set Match Vice-Captain Player</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to set a player present in the formation as vice-captain for this Match.<br/>
                    If the player specified is also captain, this role is removed, and the player will set just as vice-captain.<br/>
                    The player ID specified must be present in the match formation.<br/>
                    <br/>
                    If the special player ID "none" is specified, the status of captain is removed (if assigned to any player).<br/>
                    <br/>
                    This service works only with our team, not with the opposite team. For the opposite team use the Opponente Player Edit service.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    The list of first line players.
                  </p>
                  
            </div>
        );
  }


} 