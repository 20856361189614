

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "article/%nid/add-to-favorites";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Article ID'},
]

const serviceSample = {}


export function AddToFavorites() {
  return <AddToFavoritesComponent/>;
}


class AddToFavoritesComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Add an article to favorites</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to add an article to the user's favorites list.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of current favorite nodes.
                  </p>

            </div>
        );
  }


} 