

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "season/%sid/match/%nid/set-opponent-players";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
]

const serviceSample = [
  {
    "id": "1662543793-1",
    "lastname": "Toffoli",
    "firstname": "Giulio1",
    "primary_role_id": "1",
    "birthyear": "1978",
  },
  {
    "id": "1662543793-2",
    "lastname": "Rocca",
    "firstname": "Marco",
    "primary_role_id": "14",
    "birthyear": "1986",
  },
  {
    "lastname": "Polato",
    "firstname": "Andrea",
  }
]


export function MatchSetOpponentPlayers() {
  return <MatchSetOpponentPlayersComponent/>;
}


class MatchSetOpponentPlayersComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Set the Match Opponent Players (opponent formation)</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to create or modify the list of opponent players, set capitan and vice-capitan in a single call<br/>
                    <br/>
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                  The opponent formation in JSON format as list of objects.<br/>
                  The player ID is automaticallt genertated if not specified.<br/>
                  See Create Opponent Player for valid json fields for each player.<br/>
                  See Create Player for acceptable values for <i>primary_role</i>.<br/>
                  </p>
<CodeHighlighter code={
  [ {
    "id": "1662543793-2",
    "lastname": "Rocca",
    "firstname": "Marco",
    "primary_role_id": "14",
    "birthyear": "1986",
    "captain": true
  }]} />
  <p>
    Please note that this method allows to remove opponent players, potentially all of them. There is no consistency mantained at server side with the ids of the opponent formation referenced in system changes and first line. 
  </p>
                  
                  <h4>Response</h4>
                  <p>
                  The whole opponent formation.<br/>
                  <br/>
                  In case of validation errors, errors are indexed by field and item (player) index (starting with 1).<br/>
                  Example:
                  </p>
                  <CodeHighlighter code={
                    { "errors": {
                      "lastname_1": "Please specify at least the firstname of the player",
                      "id_3": "Player ID already used"
                    }}
                  } />
                  
                  
            </div>
        );
  }


} 