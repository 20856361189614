

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "custom-drill/%nid/image/%cid";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Custom Drill ID'},
  { name: 'cid', type: 'numeric',  mandatory: true, default: '', description: 'Image ID'}
]

const serviceSample = {}


export function CustomDrillSubImage() {
  return <CustomDrillSubImageComponent/>;
}


class CustomDrillSubImageComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Custom Drill Secondary Images</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to get a secondary image of a custom drill.
                    By default the size of the image returned is 300px wide.<br/>
                    A GET parameter <i>size</i> can be used to change this size (i.e. ?size=600).<br/>
                    The size cannot exceed 1280 pixels.
                  </p>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                        returnImage={true}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with a single attribute called data, containing a Base64 encode image data URL (like data:image/;base64,....) if the image is found, otherwise 404.
                  </p>

            </div>
        );
  }


} 