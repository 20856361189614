

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';
import { renderEndpoint } from '../utils';


const playersEndpoint = "season/%sid/players";

const playersArguments = []

const playersUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
]

const playersSample = {}

const playerStructure = {
  "pid": "2857",
  "firstname": "Alex",
  "lastname": "Dias da Costa",
  "email": "alex_test@saysource.com",
  "telephone1": "",
  "telephone2": "",
  "telephone3": "",
  "shirt_num": null,
  "primary_role": "Defender",
  "secondary_role": "(No role specified)",
  "birthyear": "",
  "birthdate": null,
  "is_active": true,
  "is_guest": false
};


export function Players() {
  return <PlayersComponent/>;
}


class PlayersComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>List Season Players</h3>

                  <p>Endpoint: <b>{renderEndpoint(playersEndpoint)}</b></p>

                  <p>
                    This service can be used to get the list of players in the season.<br />
                    If the service is executed as Player, the info of each player is limited to <i>pid</i>, <i>firstname</i>, <i>lastname</i>, <i>primary</i> and <i>secondary role</i>, <i>shirt number</i>.<br/>
                    A player is presented as follow:
                  </p>
                  <CodeHighlighter code={ playerStructure } />
                  <p>
                    <i>is_active</i> tells us that the player is available for matches and traning session (i.e. it is not currently injured)<br />
                    <i>is_guest</i> tells us that the player is just temporarly at disposal of the team<br />
                  </p>
                  
                  <ServiceForm
                        args={playersArguments}
                        urlArgs={playersUrlArguments}
                        sample={playersSample}
                        endpoint={playersEndpoint}
                        authRequired={true}
                        usePayload={false}
                        returnImage={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with a list of player objects. To get the image of a player, use the service <i>Player Image</i>
                  </p>

            </div>
        );
  }


} 