

import React from 'react';
import { CodeHighlighter } from '../components/CodeHighlighter';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/covid/event-data/%nid";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Event ID'},
]

const serviceSample = {}


export function CovidEventData() {
  return <CovidEventDataComponent/>;
}


class CovidEventDataComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>CovidEventData Data for Event</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the temperatures recorded for the players on a particular event<br/>
                  </p>
                  <CodeHighlighter code={{
                      "temperatures": {
                        "2853": {
                          "sid": "3636",
                          "nid": "5790",
                          "pid": "2853",
                          "temperature": "36",
                          "access_status": "1",
                          "remarks": "",
                          "updated_on": "1619016931"
                        },
                        "2856": {
                          "sid": "3636",
                          "nid": "5790",
                          "pid": "2856",
                          "temperature": "35.6",
                          "access_status": "1",
                          "remarks": "",
                          "updated_on": "1619016931"
                        }
                      }
                    }} />

                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the screening details.<br/>
                    The access status is one of the following:<br/>
                    <table className="table table-striped table-bordered table-hover">
                      <tbody>
                        <tr><td>1</td><td>Access Granted</td></tr>
                        <tr><td>2</td><td>Access Denied</td></tr>
                      </tbody>
                    </table>
                  </p>
                  <p>
                  </p>
                  
            </div>
        );
  }


} 