

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const matchPlayersEndpoint = "season/%sid/match/%nid/list-players";
const matchPlayersArguments = []

const matchPlayersUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
]

const matchPlayersSample = {}


export function MatchPlayers() {
  return <MatchPlayersComponent/>;
}


class MatchPlayersComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>List the Match players (formation)</h3>

                  <p>Endpoint: <b>{matchPlayersEndpoint}</b></p>

                  <p>
                    This service can be used to get the list of players added to a match and other details<br/>
                    <br/>
                  </p>
                  
                  <ServiceForm
                        args={matchPlayersArguments}
                        urlArgs={matchPlayersUrlArguments}
                        sample={matchPlayersSample}
                        endpoint={matchPlayersEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    The formation in JSON format as list of objects with the fields <i>player</i> (pid of the player), <i>number</i> and optionally the field <i>vice_captain</i> and <i>captain</i>.
                  </p>
<CodeHighlighter code={
  [{
    "player": "2861",
    "number": 8,
    "vice_captain": true
  }]} />
                  <p>
                    In order to decode the player details (first name, last name, role, image, etc...) refers to the sesason players.
                  </p>
                  
            </div>
        );
  }


} 