

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "season/%sid/training-load/hooper-data/%pid";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'pid', type: 'numeric|string',  mandatory: true, default: '', description: 'Player ID or "team" or "all-players" to get data for the entire team'}
]

const serviceSample = {}


export function TLMHooperData() {
  return <TLMHooperDataComponent/>;
}


class TLMHooperDataComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>TLM Event Data</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to get a the Hooper data for the entire season related to a specific player or the entire team.<br/>
                    The parameter <b>pid</b> can be:
                  </p>
                  <ul>
                    <li>A specific Player ID</li>
                    <li>The value 'team' for the aggregated team statistic</li>
                    <li>The value 'all-players' to get the set for the whole team</li>
                  </ul>
                  <p>
                    The structure of the JSON response is slightly different depending by the pid value (team or not team).<br/>
                  </p>
                  <p>
                    Hooper by team is some sort of team weighted average (this is not about getting all players data in bulk, use <i>all-players</i> for that).
                    In this case the objects are grouped in two superobjects: 0 which are the players who partiticate to the statistics and 1
                    who are all the others.
                  </p>
                    <CodeHighlighter code={ {
                      "0": { "date1" : {}, "date2" :{} , "date3" :{} },
                      "1": { "date1" : {}, "date2" :{} , "date3" :{} }
                      } }/>
                  <p>
                    The size of the data is not that large (considering that a season does not last more than 356 days), but being able to
                    download the entire set of data for a player is a huge benefit over multiple requests.
                  </p>
                  <p>
                    If the service is executed by a Player, only the value belonging to the player (if present) are returned, in spite of the
                    value of the %pid parameter.
                  </p>
                  <p>
                    An example of the returned JSON:
                  </p>
                  <CodeHighlighter code={ {
                      "2017-07-03": {
                        "pid": "2866",
                        "t": "0",
                        "a1": 4,
                        "a2": 4,
                        "a3": 5,
                        "a4": 4,
                        "a5": 3,
                        "p2": false
                      },
                      "2017-07-05": {
                        "pid": "2874",
                        "t": "0",
                        "a1": 2,
                        "a2": 2,
                        "a3": 3,
                        "a4": 3,
                        "a5": 1,
                        "p2": false
                      }} }/>


                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                        returnImage={false}
                  />

                  <h4>Request</h4>
                  <p>
                    GET (ATTENTION! POST is used in this case to save the data, see TLM Hooper Data Edit!!)
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON object with the hooper data.
                  </p>

                  <table className="table table-striped table-bordered table-hover">
                  <tbody>
                    <tr><th>pid</th><td>The player ID</td></tr>

                    <tr><th>t</th><td><div style={{ display: 'inline-block' }}>
                        0 => Does not participate to the team statistics<br/>
                        1 => Participate to team statistics<br/>
                                  </div>
                    </td></tr>

                    <tr><th>a*</th><td><div style={{ display: 'inline-block' }}>
                      Answer to question number (a1, a2, a3 ...).<br/>
                      The fields are:<br/>
                        a1 = Fatigue<br/>
                        a2 = Sleep Quality<br/>
                        a3 = General Muscle Soreness<br/>
                        a4 = Stress Level<br/>
                        a5 = Mood<br/>
                      The value of each field is a number between 0.5 and 5 (with step of 0.5):<br/>
                      0.5, 1.0, 1.5, 2.0, 2.5, ... 5.0
                      <hr/>
                      
                      </div>
                    </td></tr>

                    <tr><th>p2</th><td><div style={{ display: 'inline-block' }}>
                        Boolean value which indicates if this value was recorder 2 days after a match (P+2).<br/>
                        This information is mostly relevant for the TLM calculation algorithms.
                        </div>
                    </td></tr>
           
                    </tbody>
                  </table>

                  <p>
                      The meaning of the scale 0.5-5.0 is <b>0.5 = BAD</b>, <b>5.0 = GOOD</b>, always.<br/>This may be counter intuitive (i.e. 0.5 means a lot of fatigue, while 5 means not fatigue at all).<br/>
                      For this reason, in the UI of the client, it is better to not necessarily present numeric values or at leas present them in context, i.e.:<br/>
                      <i>Fatigue:</i> 0.5 Very very high ..... 5.0 Very very low.<br/>
                      <i>Sleep Quality:</i> 0.5 No sleep at all .... 5.0 Very very good<br/>
                      <i>Mood:</i> 0.5 Very very bad ..... 5.0 I feel super happy!
                  </p>


            </div>
        );
  }


} 