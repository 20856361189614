

import React from 'react';
import { CodeHighlighter } from '../components/CodeHighlighter';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "season/%sid/surveys/%nid/%survey_type/edit";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Event ID (can be a match or a training session)'},
  { name: 'survey_type', type: 'text',  mandatory: true, default: '', description: 'The survey for which we want to provide the answers'},
]

const serviceSample = {
  "2853": {
    "q1": "1",
    "q2": "2",
    "q3": "3",
    "q4": "4",
    "q5": "5",
    "q6": "This is a free text answer",
    "q7": "3",
    "q8": "4",
    "q9": "5",
    "q10": "6"
  }
};


export function SurveysDataEdit() {
  return <SurveysDataEditComponent/>;
}


class SurveysDataEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Event Survey Data Edit</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to set / modify a survey answers of one or more players for a specific event.<br/>
                    An object indexed by pid needs to be provided.<br/>
                    Each object must contain all the answers of the survey, indexed by question id.<br/>
                    Question ID and answer options depend by each survey definition and its answer type.<br/>
                    <b>Players not appearing in the JSON are not modified.</b><br/>
                  </p>
                  <p>
                    If the service is executed by a Player, only the value belonging to the player (if present) are modified.
                  </p>
                  <p>
                    The service returns an object with the saved answers or errors for each single player.
                  </p>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                        returnImage={false}
                  />

                  <h4>Request</h4>
                  <p>
                    POST with proper object
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON object indexed by player ID. For each player ID the responce contains the submitted answers or a list of errors.<br/>
                    Error example:
                  </p>
                  <CodeHighlighter code={{
  "2853": {
    "errors": {
      "q1": "Field q1 is required"
    }
  }
}} />

                  

            </div>
        );
  }


} 