

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';
import { connect } from "react-redux";
import { logout } from '../actions';


const endpoint = "logout";

const args = []
const urlArgs = []

export const sample = {}


export function Logout() {
  return <LogoutComponentConnected/>;
}


class LogoutComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>User Logout</h3>

                  <p>Endpoint: <b>{renderEndpoint(endpoint)}</b></p>

                  <p>
                    This service can be used to destroy the current user session. The service will not invalidate
                    the bearer, but discard data associated with a sepecific session, allowing a new fresh login
                  </p>
                 
                  <ServiceForm
                        args={args}
                        urlArgs={urlArgs}
                        sample={sample}
                        endpoint={endpoint}
                        authRequired={false}
                        usePayload={false}
                        serviceCallback={ this.serviceCallback }
                  />

                  <h4>Request</h4>
                  <p>
                    Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with result: ok.
                  </p>

            </div>
        );
  }
  
  serviceCallback = (res) => {
    console.log("CALLBACK!!! ", res);
    this.props.logout();
  }

}


function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()), // Username and password are read from the store
  };
}

const LogoutComponentConnected = connect(null, mapDispatchToProps)(LogoutComponent);