

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { ArgumentsTable } from '../components/ArgumentsTable';
// import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "user/remove-device-token/%token";
const serviceArguments = [];

const serviceUrlArguments = [
  { name: 'token', type: 'text',  mandatory: true, default: null, description: 'Device Token'}
];

const serviceSample = {
  'token'        : 'XYZ12340823423094-23482'
}


export function UserRemoveDeviceToken() {
  return <UserRemoveDeviceTokenComponent/>;
}


class UserRemoveDeviceTokenComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Remove a Device Token for the current user</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to remove a device token from the list of tokens registered for the current user
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with a success message o an error message
                  </p>
                  <ArgumentsTable args={serviceArguments} />
                  
                  <h4>Response</h4>
                  <p>
                    A json object with the following two possible keys:<br/>
                    <b>success</b>: ok<br/>
                    <b>error</b>: Token not found
                  </p>
                  
            </div>
        );
  }
} 