

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "season/%sid/covid/event-data/%nid/edit";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Event ID'}
]

const serviceSample = {
  "2853": {
    "temperature": "36.5",
    "access_status": "1",
    "remarks": "Can't breath properly"
  }
};


export function CovidEventDataEdit() {
  return <CovidEventDataEditComponent/>;
}


class CovidEventDataEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Covid Event Temperatures Data Edit</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to set / modify / delete temperatures data of one or more players for a specific event.<br/>
                    An object indexed by pid needs to be provided.<br/>
                    To delete the temperature for a player, set the temperature to -1.
                    <b>Players not appearing in the JSON are not modified.</b>
                  </p>
                  <p>
                    The service returns the number of records modified
                  </p>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                        returnImage={false}
                  />

                  <h4>Request</h4>
                  <p>
                    POST with JSON payload
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON object with the count of modified records
                  </p>

            </div>
        );
  }


} 