

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';

const serviceEndpoint = "send-push-notification-test";

const serviceArguments = [
  { name: 'title', type: 'text',  mandatory: true, default: '', description: 'The title of the notification'},
  { name: 'message', type: 'text',  mandatory: true, default: '', description: 'A short message'},
  { name: 'link', type: 'text',  mandatory: true, default: '', description: 'An optional link used only for browser/web notifications'},
]

const serviceUrlArguments = []

const serviceSample = {
  "title": "Hello World!",
  "message": "Training at 3pm",
  "link": "https://www.youcoach.it"
}


export function SendPushNotification() {
  return <SendPushNotificationComponent/>;
}


class SendPushNotificationComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Send Push Notification</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This is a test service to send a notification to himself.<br/>
                    The notification will be sent to all the devices tokens registered for the logged in user.<br/>
                    Please note that by visiting YouCoach.it you may already have provided your browser token to the system
                    as anonymous (until you did not logged in).<br/>
                    Push notifications are sent to mobile devices and Google Chrome browsers (other browsers do not seem to provide a token yet). 
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                        returnImage={false}
                  />

                  <h4>Request</h4>
                  <p>
                    POST
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON object with the ok notification
                  </p>

            </div>
        );
  }


} 