
import Axios from "axios";
import store from './store/index';

let authenticatedAxios = null;
let unauthenticatedAxios = null;

export const isAuthenticated = () => {
  return store.getState().authToken !== null;
}


export const getAPIAxios = () => {
  const state = store.getState();
  console.log("Getting API Axios...");
  if (state.authToken !== null) {
    if (authenticatedAxios === null) {
      console.log("Initilizing API axios: ", state.authToken);
      authenticatedAxios = Axios.create({
          baseURL: state.baseUrl,
          headers: {
              'Authorization': 'Bearer ' + state.authToken
          }
      });
    }
    else {
      console.log("Reusing axios instance...")
    }
    console.log("Returning auth axios");
    return authenticatedAxios;
  }
  console.log('Auth Token is null');

  if (unauthenticatedAxios === null) {
    unauthenticatedAxios = Axios.create({
      baseURL: state.baseUrl,
    });
  }
  console.log("Returning UNauth axios");
  return unauthenticatedAxios;
}


export const resetAPI = () => {
  authenticatedAxios = null;
  unauthenticatedAxios = null;
}
