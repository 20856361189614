

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "season/%sid/generic-events";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'}
]

const serviceSample = {}


export function GenericEvents() {
  return <GenericEventsComponent/>;
}


class GenericEventsComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>GenericEvents (events)</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the list of GenericEvents.<br/>
                    If the service is invoked by a Player, only the GenericEvents in which it is listed are returned.<br/>
                    An example generic event JSON in the returned array:
                  </p>
                  <CodeHighlighter code={ {
                      "nid": "4455",
                      "title": "This is the title (With fixed title)",
                      "description": "This is the description of a zoom webinar!!!!\r\njs js flexbox flexboxlegacy canvas canvastext webgl no-touch geolocation postmessage websqldatabase ",
                      "date": "24/10/2020 04:00",
                      "datetime_timestamp": 1603504800,
                      "players": ["2853",
                      "2854",
                      "2855"]
                  }}/>


                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of Generic Events.
                  </p>
                  
            </div>
        );
  }


} 