

import React from 'react';
import { ArgumentsTable } from '../components/ArgumentsTable';
import ServiceForm from '../components/ServiceForm';


const matchCreateEndpoint = "season/%sid/match/new";
const matchCreateArguments = [
  { name: 'date',     type: 'date',  mandatory: true, default: '', description: 'Match date using format yyyy-mm-dd hh:ii:ss (mandatory only in creation, not in edit)'},
  { name: 'home_game',  type: 'boolean',  mandatory: false, default: true, description: 'It specifies if the match is an home game or not'},
  { name: 'mode',  type: 'string',  mandatory: true, default: '', description: 'Game mode, see the modes table (mandatory only in creation, not in edit)'},
  { name: 'opponent', type: 'string',  mandatory: true, default: '', description: 'The name of the opponent team (mandatory only in creation, not in edit)'},
  { name: 'type',  type: 'integer',  mandatory: false, default: '', description: 'Optional type of game, see game types table'},
  { name: 'type_name',  type: 'string', mandatory: false, default: '', description: 'Name of the championship or other game name'},
  { name: 'number',  type: 'string', mandatory: false, default: '', description: 'Optional serial number in the context of the championship'},
  { name: 'num_players',  type: 'integer', mandatory: true, default: '11', description: 'Number of players (usually 5, 7 or 11) (mandatory only in creation, not in edit)'},
  { name: 'city', type: 'string',  mandatory: false, default: '', description: 'City of the match'},
  { name: 'location', type: 'string',  mandatory: false, default: '', description: 'The match location'},
  { name: 'address', type: 'string',  mandatory: false, default: '', description: 'The match address'},
  { name: 'country', type: 'string',  mandatory: false, default: '', description: 'ISO two letters country code'},
  { name: 'formation', type: 'array',  mandatory: false, default: '', description: 'If a formation array is provided, it will be saved. See MatchSetPlayers for details on the formation array'},
  { name: 'durations', type: 'array',  mandatory: false, default: '', description: 'If a durations array is provided, it will be saved.'}
]

const matchCreateUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
]

const matchCreateSample = {
  'date'        : '2020-10-25 10:30:00',
  'home_game'   : true,
  'mode'        : 'goals',
  'opponent'    : 'A.C. Milan',
  'type'        : '1',
  'type_name'   : 'Serie A Tim',
  'number'      : '1',
  'num_players' : 11,
  'city'        : 'Padova',
  'location'    : 'Stadio Euganeo',
  'address'     : 'Viale Nereo Rocco, 60',
  'country'     : 'IT',
  'durations'   : [
    { 'type': 1, 'minutes': 45 },
    { 'type': 2, 'minutes': 45 }
  ]
}


export function MatchCreate() {
  return <MatchCreateComponent/>;
}


class MatchCreateComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Create a new Match</h3>

                  <p>Endpoint: <b>{matchCreateEndpoint}</b></p>

                  <p>
                    This service can be used to create a new Match.<br/>
                    <br/>
                  </p>
                  
                  <ServiceForm
                        args={matchCreateArguments}
                        urlArgs={matchCreateUrlArguments}
                        sample={matchCreateSample}
                        endpoint={matchCreateEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with the match data.
                  </p>
                  <ArgumentsTable args={matchCreateArguments} />

                  <table className="table table-striped table-bordered table-hover">
                  <tbody>
                    <tr><th>mode</th><td><i>goals</i> => Score based on goals<br/>
                                          <i>parts</i> => Score based on parts</td></tr>
                    <tr><th>type</th><td><div style={{ display: 'inline-block' }}>1 => Campionato<br/>
                                  2 => Amichevole<br/>
                                  3 => Rappresentativa<br/>
                                  4 => Torneo<br/>
                                  5 => Allenamento<br/>
                                  6 => Play Off<br/>
                                  7 => Play Ouf<br/>
                                  8 => Coppa<br/>
                                  100 => Altro
                                  </div>
                                </td></tr>
                    </tbody>
                  </table>
                 
                  <h5>Durations</h5>
                  <p>If not specified, the durations default to the First and Second half (45 mins each).<br/>
                  The durations is an array with all the parts of the match. Each part is specified as an object with the following fields:</p>

                  <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Default</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr><td><b>type<sup style={{ color: 'red' }}>*</sup></b></td>
                        <td>integer</td>
                        <td></td>
                        <td><div style={{ display: 'inline-block' }}>1 => First Half<br/>
                                  2 => Second Half<br/>
                                  3 => Third Half<br/>
                                  4 => Fourth Half<br/>
                                  5 => First Extra Time<br/>
                                  6 => Second Extra Time<br/>
                                  0 => Other<br/>
                                  100 => Penalty Time
                                  </div>
                                </td></tr>
                    <tr><td>name</td>
                        <td>text</td>
                        <td></td>
                        <td>Name of the part (useful when type is 0)</td></tr>
                    <tr><td><b>minutes<sup style={{ color: 'red' }}>*</sup></b></td>
                        <td>positive integer</td>
                        <td></td>
                        <td>Minutes of this part (not mandatory and ignored for type 100)</td></tr>
                    <tr><td>extra_minutes</td>
                        <td>integer</td>
                        <td></td>
                        <td>Number of minutes spend in the injury time for this part (ignored for type 100)</td></tr>
                    </tbody>
                  </table>

                  <h4>Response</h4>
                  <p>
                    The created match as JSON
                  </p>
                  
            </div>
        );
  }


} 