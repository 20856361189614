

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "season/%sid/training-load/hooper-data/edit?normalized=%normalized";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'normalized', type: 'numeric',  mandatory: false, default: '0', description: 'Type of data: 1 = normalized, 0 = not normalized'},
]

const serviceSample = [{
    "date": "2017-07-03",
    "pid": "2866",
    "a1": 0.5,
    "a2": 1,
    "a3": 1.5,
    "a4": 2,
    "a5": 2.5
  },
  {
    "date": "2017-07-03",
    "pid": "2867",
    "t": "0",
    "a1": 5,
    "a2": 4.5,
    "a3": 4,
    "a4": 3.5,
    "a5": 3
  }];


export function TLMHooperDataEdit() {
  return <TLMHooperDataEditComponent/>;
}


class TLMHooperDataEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>TLM Hooper Data Edit</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to set / modify the Hooper data of one or more players for a specific event.<br/>
                    The input is an array of data objects containing the following fields:
                  </p>
                  <table className="table table-striped table-bordered table-hover">
                  <tbody>
                    <tr><th>date</th><td>The date of the Hooper</td></tr>

                    <tr><th>pid</th><td>The player ID</td></tr>

                    <tr><th>t</th><td>(optional) Leave it blank (null or '') for default value.<br/>
                        0 => Does not participate to the team statistics<br/>
                        1 => Participate to team statistics<br/>
                    </td></tr>

                    <tr><th>a*</th><td>
                      Answer to question number (a1, a2, a3 ...).<br/>
                      The fields are:<br/>
                        a1 = Fatigue<br/>
                        a2 = Sleep Quality<br/>
                        a3 = General Muscle Soreness<br/>
                        a4 = Stress Level<br/>
                        a5 = Mood<br/>
                      The value of each field is a number between 0.5 and 5 (with step of 0.5):<br/>
                      0.5, 1.0, 1.5, 2.0, 2.5, ... 5.0.<br/>
                      For the meaning of the values, please see the service "TLM Hooper Data".<br/>In general the meaning of the scale for each field is 0.5 = BAD, 5.0 = GOOD.<br/>
                      The acquisition interface need to send values accordingly.
                    </td></tr>
           
                    </tbody>
                  </table>
                  <p>
                    <b>Players not appearing in the JSON are not modified.</b>
                  </p>
                  <p>
                    <b>The "normalized" parameter</b> controls the way the data needs to be interpreted by the REST service. In the versions of the mobile app pre 1.0.5, the app was sending the values
                    with a different (intuitive) meaning, while from the next version the server only provides and stores data in normalized form, as shown in table:<br/>
                  </p>
                   
                  <table className="table table-striped table-bordered table-hover">
                    <tbody>
                    <tr><th>Name</th><th>Field</th><th>Intuitive meaning</th><th>Normalized meaning</th></tr>

                    <tr><td>Fatigue</td><td>a1</td><td>
                          <span className="text-success"><b>0.5 = Good, low fatigue</b></span><br/>
                          <span className="text-danger"><b>5 = Bad, high fatigue</b></span></td>
                        <td>
                          <span className="text-danger"><b>0.5 = Bad, high fatigue</b></span><br/>
                          <span className="text-success"><b>5.0 = Low fatigue</b></span></td></tr>
                    <tr><td>Sleep Quality</td><td>a2</td><td>
                          <span className="text-danger">0.5 = Bad sleep</span><br/>
                          <span className="text-success">5 = Very good sleep</span></td>
                        <td>
                          <span className="text-danger">0.5 = Bad sleep</span><br/>
                          <span className="text-success">5 = Very good sleep</span></td></tr>
                    <tr><td>General Muscle Soreness</td><td>a3</td><td>
                          <span className="text-success"><b>0.5 = Good, low soreness</b></span><br/>
                          <span className="text-danger"><b>5 = Bad, high soreness</b></span></td>
                        <td>
                          <span className="text-danger"><b>0.5 = Bad, high soreness</b></span><br/>
                          <span className="text-success"><b>5.0 = Low soreness</b></span></td></tr>
                    <tr><td>Stress Level</td><td>a4</td><td>
                          <span className="text-success"><b>0.5 = Good, low stress</b></span><br/>
                          <span className="text-danger"><b>5 = Bad, high stress</b></span></td>
                        <td>
                          <span className="text-danger"><b>0.5 = Bad, high stress</b></span><br/>
                          <span className="text-success"><b>5.0 = Low stress</b></span></td></tr>
                    <tr><td>Mood</td><td>a5</td><td>
                          <span className="text-danger">0.5 = Very bad mood</span><br/>
                          <span className="text-success">5 = I feel very happy</span></td>
                        <td>
                           <span className="text-danger">0.5 = Very bad mood</span><br/>
                           <span className="text-success">5 = I feel very happy</span></td></tr>
                    </tbody>
                  </table>

                    {/* <i>Fatigue:</i> 0.5 Very very high ..... 5.0 Very very low.<br/>
                      <i>Sleep Quality:</i> 0.5 No sleep at all .... 5.0 Very very good<br/>
                      <i>Mood:</i> 0.5 Very very bad ..... 5.0 I feel super happy! */}

                  <p>
                    If the service is executed by a Player, only the value belonging to the player (if present) are modified.
                  </p>
                  <p>
                    The service returns the number of update/created records.
                  </p>
                  <CodeHighlighter code={ { 'modified': 3 } } />

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                        returnImage={false}
                  />

                  <h4>Request</h4>
                  <p>
                    POST
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON object with the number of update/created records.
                  </p>

            </div>
        );
  }


} 