
import React from 'react';
import { connect } from "react-redux";
import Form from 'react-bootstrap/Form';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import './prism.css';
import Button from 'react-bootstrap/Button';
import { CodeHighlighter } from './CodeHighlighter';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Frame from 'react-frame-component';

import { authenticate, logout, authenticationFail, authenticationSuccess } from '../actions/index';
import { getAPIAxios, isAuthenticated, resetAPI } from '../API';

import './mobile.png';
import { ArgumentsTable } from './ArgumentsTable';


class ServiceForm extends React.Component {


    state = {
      payload: '{}',
      response: null,
      responseContentType: null,
      error: null,
      working: false,
      urlArgs: {},
      iFrameUrl: null,
      username: '',
      serviceCallback: null
    }

    componentDidMount() {
      this.setState( { payload: JSON.stringify(this.props.sample, null, 2) })
    }

    render() {

        let urlArgs = null;
        if (this.props.urlArgs && this.props.urlArgs.length > 0) {
            urlArgs = this.props.urlArgs.map( (a, index) => {
                return <Form.Group key={index}>
                        <Form.Label><b>{ a.name }</b></Form.Label>
                        <Form.Control type="text" value={ typeof this.state.urlArgs[a.name] !== 'undefined' ? this.state.urlArgs[a.name] : '' } name={a.name} onChange={ this.updateArgument } />
                      </Form.Group>
            });
        }

        if (!urlArgs && !this.props.usePayload) {
          urlArgs = 'No payload or URL parameters are required';
        }

        let runWithAuth = null;
        if (!this.props.authRequired && !isAuthenticated() && !this.props.isLogin) {
            runWithAuth = <Button variant="primary" type="button" style={{ marginLeft: 10 }} onClick={ this.runExampleWithAuth }>
            Run Example (with authentication)
          </Button>;
        }

        return <>

                { this.props.urlArgs && this.props.urlArgs.length > 0 ? <><h4>Endpoint Arguments</h4>
                <ArgumentsTable args={this.props.urlArgs} /></> : null }

                <h4>Example</h4>
                <Form onSubmit={this.handleSubmit}>
                    { this.props.usePayload ? 
                    <Editor
                        value={this.state.payload}
                        onValueChange={this.onPayloadChange}
                        highlight={code => highlight(code, languages.json)}
                        padding={10}
                        className="jsonEditor"
                    /> :  null }

                    { urlArgs ? <div className="infoEditor">{urlArgs}</div> : null}

                  <div className="formActions">
                    <Button variant="primary" type="button" onClick={ this.runExample }>
                      Run Example
                    </Button>
                    { runWithAuth }
                    { isAuthenticated() ? <small style={{ marginLeft: 10 }}>You are currently authenticated with user <b>{ this.props.username }</b></small> : null }
                  </div>

                </Form>

                { this.showResponseCode('Response', this.state.response) }
                { this.showResponseImage( this.state.response ) }
                { this.props.useIframe ? this.showResponseFrame( this.state.response ) : null }
                { this.showWorking() }
                { this.showError() }

              </>
    }


    handleSubmit = (event) => {
      event.preventDefault();
    }

    onPayloadChange = (payload) => {
      this.setState({ payload });
      if (this.props.onPayloadChange) {
        this.props.onPayloadChange(payload);
      }
    }

    showWorking = () => {
      if (this.state.working) {
          return <div className="d-flex justify-content-center"><Spinner  style={{ marginTop: '1rem'}}  animation="grow" /></div>
      }

      return null;
    }

    showError = () => {
      if (this.state.error) {
          if (Array.isArray(this.state.error)) {
              const errors = this.state.error.map( (a, k) => <li key={k}>{a}</li>);
              return  <Alert variant="danger" style={{ marginTop: '1rem'}} >{ errors }</Alert>
          }
          return <Alert variant="danger" style={{ marginTop: '1rem'}} >{ this.state.error }</Alert>
      }

      return null;
    }

    showResponseCode = (title, code) => {

        if (!code) {
          return null;
        }

        return <div style={{ marginTop: '1rem', overflow: 'scroll', maxHeight: 500 }}>
                    <p>{ title }</p>
                    <CodeHighlighter code={code} />
                    
               </div>
    }

    showResponseImage = (response) => {

      if (!response) {
        return null;
      }

      if (this.props.returnImage) {
        let imageData = "ciccia";
        if (this.props.returnImageExtractor !== undefined) {
          imageData = this.props.returnImageExtractor(response);
        }
        else {
          imageData = response.data;
        }
        return <div style={{ marginTop: '1rem'}}>
                  <img alt="Decoded" src={ imageData } style={{ maxWidth: '300px', maxHeight: '300px' }} />
               </div>
      }

      return null;
    }

    showResponseFrame = (response) => {

      if (!response) {
        return null;
      }

      if (this.state.responseContentType === 'application/octet-stream') {
        // Potrebbe essere un PDF...
        console.log("Response:", response);

        const b2 =  new Blob([response], {
          type: "application/pdf",
        });
        let url = window.URL.createObjectURL(b2);

        // console.log(url);

        return <div style={{ marginTop: '1rem'}} className="previewFrame bnaryFile">
            <object type="application/pdf" data={url} style={{ width: '100%', height: '100%'}} />
          </div>;
      } else if (this.props.isPdf) {
        

        const b2 =  b64toBlob(response.data,"application/pdf");
        let url = window.URL.createObjectURL(b2);

        // console.log(url);

        return <div style={{ marginTop: '1rem'}} className="previewFrame bnaryFile">
            <object type="application/pdf" data={url} style={{ width: '100%', height: '100%'}} />
          </div>;
      }

      return <div style={{ marginTop: '1rem'}} className="previewFrame">
                <Frame initialContent={this.state.response.content}  />
              </div>
    }

    onError = (error) => {
      console.log(error);
      const message = (typeof error.data !== 'undefined' && typeof error.data.error !== 'undefined') ? error.data.error : error;
      this.setState({ error: 'An error occurred executing the service: ' + message });
    }

    updateArgument = (event) => {
        const argName = event.target.name;
        const value = event.target.value;

        const urlArgs = { ...this.state.urlArgs };
        urlArgs[argName] = value;
        
        this.setState( { urlArgs });
    }

    runExampleWithAuth = () => {
        if (!isAuthenticated()) {
          authenticate( this.runExample, this.onError );
        }
        else {
          this.runExample();
        }
    }


    runExample = () => {

        this.setState({ error: null });
        console.log("runExample");
        if (!this.createServiceEndpoint()) {
          console.log("Creating service point failed");
          return;
        }
        if (this.props.authRequired && !isAuthenticated()) {
          console.log("preauthenticating");
            this.props.authenticate( this.runExample, this.onError );
            return;
        }

        if (this.props.isLogin && isAuthenticated()) {
          console.log("Performing logout...");
          this.props.logout();
        }

        this.runService();
    }


    createServiceEndpoint = () => {

        let endpoint = this.props.endpoint;

        if (this.props.urlArgs && this.props.urlArgs.length > 0) {
          let errors = [];

          endpoint = this.props.urlArgs.reduce(  (accumulator, a) => {
            const val  = typeof this.state.urlArgs[a.name] !== 'undefined' ? this.state.urlArgs[a.name] : '';
            if (val.trim().length === 0 && a.mandatory) {
              errors.push('Parameter ' + a.name + ' is required');
            }
            return accumulator.replace('%' + a.name,  val);
          }, endpoint);

          if (errors.length > 0) {
            this.setState({ error: errors });
            return null;
          }
        }

        return endpoint;
    }


    runService = () => {

        const endpoint = this.createServiceEndpoint();
        const aAxios = getAPIAxios();
        
        this.setState({ working: true,
                        error: null,
                        response: null
                      });

        let config = {};

        if (this.props.responseType) {
          let responseType = this.props.responseType;
          if (typeof this.props.responseType === 'function') {
            responseType = this.props.responseType(this.state.urlArgs, this.state.payload);
          }
          config = { responseType: responseType };
        }

        aAxios.post( endpoint, this.state.payload, config)  
              .then( (response) => {
                  this.setState({ response: response.data, responseContentType: response.headers['content-type'] });

                  if (this.props.isLogin) {
                    this.props.authenticationSuccess(response.data.token, response.data.refresh_token);
                    resetAPI();
                  }

                  if (this.props.serviceCallback) {
                    this.props.serviceCallback(response.data)
                  }
              })
              .catch( (error) => {
                    console.log("An error has occurred!", error.response);
                    let details = '';
                    if (typeof error.response !== 'undefined' &&
                        typeof error.response.statusText !== 'undefined') {
                      details = ' ' + error.response.statusText;
                    }
                    this.setState({ error: '' + error + details });

                    if (this.props.isLogin) {
                      this.props.authenticationFail(error.response);
                    }
              })
              .finally( () => {
                  this.setState({ working: false });
              });

        console.log("Example run!");
    }
}

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const mapStateToProps = state => {
  return { 
            authToken: state.authToken,
            username: state.username,
         };
};


function mapDispatchToProps(dispatch) {
  return {
    authenticate: (succCb, errorCb) => dispatch(authenticate(succCb, errorCb)), // Username and password are read from the store
    logout: () => dispatch(logout()),
    authenticationFail: (error) => dispatch(authenticationFail(error)),
    authenticationSuccess: (authToken, refreshToken) => dispatch(authenticationSuccess(authToken, refreshToken)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceForm);