

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "season/%sid/training-session/%nid/attendance/edit";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Training Session ID'}
]

const serviceSample = {
    "2853": {
      "attendance": "AE",
      "notes": "Some optional note here",
    },
    "2854": {
      "pid": "2854",
      "remove": true
    }
};


export function TrainingSessionAttendanceEdit() {
  return <TrainingSessionAttendanceEditComponent/>;
}


class TrainingSessionAttendanceEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Training Sessione Attendance Edit</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to set / modify / delete attendance info for one or more players for a specific training session.<br/>
                    An object indexed by pid needs to be provided.<br/>
                    To delete the attendance of a player set the attribute "remove" to TRUE.
                    <b>Players not appearing in the JSON are not modified.</b><br/>
                    Check the service Training Session Attendance for possible values for the field <i>attendance</i>.
                  </p>
                  <p>
                    The service returns the number of records modified
                  </p>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                        returnImage={false}
                  />

                  <h4>Request</h4>
                  <p>
                    POST with JSON payload
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON object with the count of modified and removed records
                  </p>

            </div>
        );
  }


} 