

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "season/%sid/training-session/%tid/notes/%nid";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'tid', type: 'numeric',  mandatory: true, default: '', description: 'Training Session ID'},
  { name: 'nid', type: 'numeric | "new"',  mandatory: true, default: '', description: 'Note ID or the keyword "new" to create a new note'}
]

const serviceSample = {
    "text": "This is my note!"
};


export function TrainingSessionNoteEdit() {
  return <TrainingSessionNoteEditComponent/>;
}


class TrainingSessionNoteEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Training Sessione Create/Edit Note</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to modify a note for a specific training session.<br/>
                    Only our owns note can be modified.<br/>
                  </p>
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                        returnImage={false}
                  />

                  <h4>Request</h4>
                  <p>
                    POST with JSON payload
                  </p>
                  <h4>Response</h4>
                  <p>
                  The service returns the serialized version of the note ({"{"} note: ...note object... {"}"}or a list of errors ({"{"} errors: [] {"}"}).
                  </p>

            </div>
        );
  }


} 