

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "season/%sid/training-load/event-data/%nid";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Event ID (can be a match or a training session)'}
]

const serviceSample = {}


export function TLMEventData() {
  return <TLMEventDataComponent/>;
}


class TLMEventDataComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>TLM Event Data</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to get a the RPE and time spent training/playing of players for a specific event.<br/>
                    Values are returned for all the players, BUT the rpe value is null in case the value has never been stored.<br/>
                    The time (minutes) can also be null, but in general, if the player actually participated to the event, the time
                    is deducted by the specific type of event and should be considered as "proposed time" in case of RPE is specified
                    and the record saved on DB.<br/>
                  </p>
                  <p>
                    If the service is executed by a Player, only the value belonging to the player is returned.
                  </p>
                  <p>
                    An example of the returned JSON:
                  </p>
                  <CodeHighlighter code={ {
                    "2853": {
                      "pid": 2853,
                      "time": "108",
                      "rpe": "1.1"
                    },
                    "2854": {
                      "pid": 2854,
                      "time": "108",
                      "rpe": "9.6"
                    },
                    "2855": {
                      "pid": 2855,
                      "time": "108",
                      "rpe": "3.5"
                    }
                   } }/>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                        returnImage={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON object indexed by player ID.
                  </p>

            </div>
        );
  }


} 