

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "items/%type";

const serviceArguments = [];

const serviceUrlArguments = [
  { name: 'type', type: 'string',  mandatory: true, default: '', description: 'The items to get'},
]

const serviceSample = {}


export function Items() {
  return <ItemsComponent/>;
}


class ItemsComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Items</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the list items to be used in specific comboboxes.
                    The result is always a list of objects like this:
                  </p>
                  <CodeHighlighter code={ {
                      "id": "4287",
                      "value": "Some string"
                    }
                  }/>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                     Empty POST or GET.<br/>
                     Possible lists of items:
                  </p>
                  <ul>
                    <li><b>categories</b> - Is used in the form to create a new Season</li> 
                  </ul>
                    
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of items.
                  </p>
                  
                  

            </div>
        );
  }


} 