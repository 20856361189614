

import React from 'react';
import { CodeHighlighter } from '../components/CodeHighlighter';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/player/%nid/stats-matches";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Player ID'},
]

const serviceSample = {}


export function PlayerStatsMatches() {
  return <PlayerStatsMatchesComponent/>;
}


class PlayerStatsMatchesComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Player Statistics for Matches</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service a fast service to get the basic aggregated events of a player for all the season's matches<br/>
                    It is much less powerful than PlayerStats service, but much faster.
                  </p>
                  <p>
                    An example of the returned (huge) JSON:
                  </p>
                  <CodeHighlighter useScroll={true} code={ [
                          {
                            "pid": "2853",
                            "mid": "2850",
                            "stat_type": "stats_played",
                            "stat_value": "1"
                          },
                          {
                            "pid": "2853",
                            "mid": "2850",
                            "stat_type": "stats_titolare",
                            "stat_value": "1"
                          },
                          {
                            "pid": "2853",
                            "mid": "3615",
                            "stat_type": "stats_minuti",
                            "stat_value": "90"
                          } ] }/>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the stats.
                  </p>

                  <b>Event Types</b>
                  <table className="table table-striped table-bordered table-hover">
                    <tbody>
                      <tr><th style={{ width: '1%' }}>Event Type</th><th>Description</th></tr>
                      <tr><td style={{ width: '1%' }}>stats_minuti</td><td>Number of minutes actually played on field</td></tr>
                      <tr><td style={{ width: '1%' }}>stats_played</td><td>If 1, it means the user was among the players selected for the match (this does not mean it actually played)</td></tr>
                      <tr><td style={{ width: '1%' }}>stats_titolare</td><td>The user started playing from the start of the match</td></tr>
                      <tr><td style={{ width: '1%' }}>stats_benches</td><td>The user was in bench (it may have entered the game after a substitution)</td></tr>
                      <tr><td style={{ width: '1%' }}>stats_goals</td><td>Total number of scored goals</td></tr>
                      <tr><td style={{ width: '1%' }}>stats_assists</td><td>Total number of assists for scored goals</td></tr>
                      <tr><td style={{ width: '1%' }}>stats_sostituzione_in</td><td>The player entered the field as consequence of a subsctitution</td></tr>
                      <tr><td style={{ width: '1%' }}>stats_sostituzione_out</td><td>The player left the field as consequence of a subsctitution</td></tr>
                      <tr><td style={{ width: '1%' }}>stats_rcards</td><td>Red cards (nornally no more than one for a single game)</td></tr>
                      <tr><td style={{ width: '1%' }}>stats_ycards</td><td>Yellow cards (nornally no more than one or two per game)</td></tr>
                      <tr><td style={{ width: '1%' }}>stats_injuries</td><td>Number of injuries (nornally no more than one for a single game)</td></tr>
                    </tbody>
                  </table>
                  
            </div>
        );
  }


} 