

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "article/%nid/remove-from-favorites";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Article ID'},
]

const serviceSample = {}


export function RemoveFromFavorites() {
  return <RemoveFromFavoritesComponent/>;
}


class RemoveFromFavoritesComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Remove an article from favorites</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to remove an article from the user's favorites list.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of current favorite nodes.
                  </p>

            </div>
        );
  }


} 