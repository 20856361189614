

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const endpoint = "articles/last-teasers";

const args = []

export const sample = {}


export function Articles() {
  return <ArticlesComponent/>;
}


class ArticlesComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Last Article Teasers</h3>

                  <p>Endpoint: <b>{renderEndpoint(endpoint)}</b></p>

                  <p>
                    This service can be used to get the list of the latest published article teasers.
                  </p>

                  <ServiceForm
                        args={args}
                        sample={sample}
                        endpoint={endpoint}
                        authRequired={false}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of articles.
                  </p>

            </div>
        );
  }
  
} 