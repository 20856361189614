

import React from 'react';
import { CodeHighlighter } from '../components/CodeHighlighter';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "season/%sid/training-session/%tid/notes";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'tid', type: 'numeric',  mandatory: true, default: '', description: 'Training Session ID'}
]

const serviceSample = {};


export function TrainingSessionNotes() {
  return <TrainingSessionNotesComponent/>;
}


class TrainingSessionNotesComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Training Sessione List Notes</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to get the list of notes of a training session. Usually we don't have long discussions, so a not-paginated service is ok.<br/>
                    Also consider that this list is also included in the training session details, so it can be used to just quickly refresh the list of notes if required,
                    since it is faster than ask for the whole training session details.<br/>
                  </p>
                  <CodeHighlighter code={[
                      {
                        "nid": "5792",
                        "author": "1",
                        "author_display_name": "Giulio Toffoli",
                        "body": "This is my note 3!",
                        "timestamp": "1619167544"
                      },
                      {
                        "nid": "5793",
                        "author": "1",
                        "author_display_name": "Giulio Toffoli",
                        "body": "This is a new note changed!",
                        "timestamp": "1619173710"
                      }
                    ]} />
                  

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                        returnImage={false}
                  />

                  <h4>Request</h4>
                  <p>
                    GET or POST
                  </p>
                  <h4>Response</h4>
                  <p>
                  The service returns the serialized version of the notes in an array.
                  </p>

            </div>
        );
  }


} 