

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "season/%sid/match/%mid/playset-schema-image/%spid";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'mid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
  { name: 'spid', type: 'numeric',  mandatory: true, default: '', description: 'Set Play ID'},
]

const serviceSample = {}


export function MatchSetPlayImage() {
  return <MatchSetPlayImageComponent/>;
}


class MatchSetPlayImageComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Set Play Schema Image</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to get the image of a schema used as Play Set in a match.<br/>
                    If the setplay specified actually exists, but it does not have an image, a default image is used.<br/>
                    Images are generated when the setplay is created, so resaving the setplay schema may solve a problem
                    of a missing image.
                  </p>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={false}
                        usePayload={false}
                        returnImage={true}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with a single attribute called data, containing a Base64 encode image data URL (like data:image/;base64,....) if the image is found, otherwise 404.
                  </p>

            </div>
        );
  }


} 