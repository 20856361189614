

import React from 'react';
import { CodeHighlighter } from '../components/CodeHighlighter';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/surveys/%nid/info?pid=%pid&survey_type=%survey_type";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Node ID of an event (either a match or a training session'},
  { name: 'pid', type: 'numeric',  mandatory: false, default: '0', description: 'Player ID. By default is 0, which is the whole team.'},
  { name: 'survey_type', type: 'text',  mandatory: false, default: '', description: 'Filter info on a specific survey type'},
]

const serviceSample = {}


export function SurveysData() {
  return <SurveysDataComponent/>;
}


class SurveysDataComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Data and info about surveys of an event</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get info about surveys tied to a specific event (match or training session)<br/>
                    Possible survey types are currently:
                  </p>
                  <ul>
                    <li>pre_training</li>
                    <li>post_training</li>
                    <li>pre_match</li>
                    <li>post_match</li>
                  </ul>
                  <p>
                    An example of the returned JSON:
                  </p>
                  <CodeHighlighter code={ {
  "pre_training": {
    "survey_id": "pre_training",
    "name": "Pre-allenamento",
    "enabled": true,
    "open": true,
    "questions": [
      {
        "id": "q1",
        "short_name": "Sonno",
        "question": "Come hai dormito?",
        "answer_type": "1-10"
      },
      {
        "id": "q2",
        "short_name": "Stanchezza",
        "question": "Quanto stanco ti senti?",
        "answer_type": "1-10"
      },
      {
        "id": "q3",
        "short_name": "Prestazioni",
        "question": "Quanto ritieni di poter essere performante?",
        "answer_type": "1-10"
      }
    ],
    "answers": {
      "2854": {
        "submitted_on": "2021-08-31 21:20:48",
        "submitted_by": "1",
        "answers": {
          "q1": "2",
          "q2": "3",
          "q3": "4"
        }
      },
      "2857": {
        "submitted_on": "2021-08-31 19:47:33",
        "submitted_by": "1",
        "answers": {
          "q1": "1",
          "q2": "6",
          "q3": "2"
        }
      }
    }
  },
  "post_training": {
    "survey_id": "post_training",
    "name": "Post-allenamento",
    "enabled": true,
    "open": true,
    "questions": [
      {
        "id": "q20",
        "short_name": "Intensitá",
        "question": "Quanto intenso ritieni sia stata la sessione di allenamento/partita?",
        "answer_type": "1-10q"
      },
      {
        "id": "q21",
        "short_name": "Fatica",
        "question": "Quanta fatica hai fatto?",
        "answer_type": "1-10q"
      },
      {
        "id": "q22",
        "short_name": "Soddisfazione",
        "question": "Quanto ti senti soddisfatto?",
        "answer_type": "1-10"
      }
    ],
    "answers": []
  } } }/>
                  <p>  
                  A survey can be enabled or disabled, this depends by the season settings (and if the coach wants to actually use that survey).<br/>
                  A survey can also be open or closed: this is an event specific status of the survey and can be used by the coach i.e. to allow players to fill the survey only at the end of a training session and just for few hours.<br/>
                  A disabled or closed survey cannot receive any new/updated answers</p>
                  <p>  
                  If the service is executed by a team member (a player), only its own answers are provided, in spite of the value of the <i>pid</i> parameter.
                  </p>
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the generic event details.
                  </p>
                  <p>
                  </p>
                  
            </div>
        );
  }


} 