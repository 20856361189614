

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "season/%sid/training-load/settings";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'}
]

const serviceSample = {}


export function TLMSettings() {
  return <TLMSettingsComponent/>;
}


class TLMSettingsComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>TLM Event Settings</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to get a the TLM Settings.<br/>
                    Settings are composed by two parts:
                  </p>
                  <ul>
                    <li>When Hooper needs to be acquired</li>
                    <li>What Hooper info needs to be acquired</li>
                  </ul>
                  <b>When Hooper needs to be acquired</b>
                  <p>
                    The Hooper data can be acquired following a specific "frequency":
                  </p>
                  <table className="table table-striped table-bordered table-hover">
                    <tbody>
                      <tr><th>frequency</th><td><div style={{ display: 'inline-block' }}>
                        1 => Training and match days<br/>
                        2 => Specific days of the week<br/>
                                  </div>
                      </td></tr>
                    </tbody>
                  </table>
                  <p>
                    In case of specific days, days starts with 1...:<br/>
                    1 = Monday<br/>
                    2 = Tuesday<br/>
                    3 = Wednesday<br/>
                    4 = Thursday<br/>
                    5 = Friday<br/>
                    6 = Saturday<br/>
                    7 = Sunday<br/>
                  </p>

                  <b>What Hooper info needs to be acquired</b>
                  <p>
                    The possible choices are:<br/>
                    1 = Fatigue<br/>
                    2 = Sleep Quality<br/>
                    3 = General Muscle Soreness<br/>
                    4 = Stress Level<br/>
                    5 = Mood<br/>
                  </p>
                  <p>
                    <i>show_monotony_strain</i> can be ignored.
                  </p>
                  <p>
                    An example of the returned JSON:
                  </p>
                  <CodeHighlighter code={ {
                      "hooper_frequency": "1",
                      "hooper_days": [
                        1,
                        2,
                        3,
                        4,
                        5,
                        6,
                        7
                      ],
                      "hooper_questions": [
                        1,
                        2,
                        3,
                        4,
                        5
                      ],
                      "show_monotony_strain": "0"
                    } }/>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                        returnImage={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON object with the settings
                  </p>

            </div>
        );
  }


} 