

import React from 'react';
import { CodeHighlighter } from '../components/CodeHighlighter';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/player/%nid/stats";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Player ID'},
]

const serviceSample = {}


export function PlayerStats() {
  return <PlayerStatsComponent/>;
}


class PlayerStatsComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Player Statistics</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get stats about a Player<br/>
                  </p>
                  <p>
                    An example of the returned (huge) JSON:
                  </p>
                  <CodeHighlighter useScroll={true} code={ {
  "date_start": "2017-07-03",
  "date_end": "2020-12-28",
  "cycle_name": null,
  "season_start": {
    "date": "2017-07-03 16:17:00.000000",
    "timezone_type": 3,
    "timezone": "Europe/Rome"
  },
  "season_end": {
    "date": "2021-09-30 16:17:00.000000",
    "timezone_type": 3,
    "timezone": "Europe/Rome"
  },
  "season_completion": 82.19354838709677,
  "period_start": 0,
  "period_end": 82.19354838709677,
  "period_length": 82.19354838709677,
  "player": {
    "firstname": "Christian",
    "lastname": "Abbiati",
    "display_shirt_num": false,
    "primary_role": "Portiere",
    "primary_role_id": "1",
    "secondary_role": "(Nessun ruolo specificato)",
    "secondary_role_id": "0",
    "category": "Portieri",
    "category_key": "00001",
    "email": "player1@saysource.com",
    "personal_id": "AA12345567",
    "personal_id_type": "C.I.",
    "personal_id_date": "20/12/2005",
    "birthdate": "13/07/1976",
    "birthyear": "1976",
    "birthplace": "",
    "height": "",
    "weight": "",
    "foot_type_id": "0",
    "foot_type": "Non specificato",
    "nationality": "Non specificato",
    "nationality_iso2": "0",
    "federation_id": "",
    "address": "",
    "city": "",
    "telephone1": "",
    "telephone2": "",
    "telephone3": "",
    "notes": "",
    "strengths": "5453534534<br />\n<br />\nAltra modifica!",
    "weaknesses": "fwegwregwergwr",
    "can_edit": true,
    "can_edit_access": true,
    "can_delete": true,
    "action_links": "<a href=\"/it/mycoach/season/3636/players/2853/edit\" class=\"btn-small\"><span class=\"glyphicon glyphicon-pencil\"></span></a><a href=\"/it/mycoach/season/3636/players/2853/delete\" class=\"btn-small yc-confirm-delete\"  yc-confirm-message=\"Sei sicuro di voler rimuovere questo giocatore (Abbiati, Christían dell'Abbiáti)?<br>Il giocatore verrà rimosso dalla squadra ed eliminato dal database se non gioca in altre squadre/stagioni di questo Club.\"><span class=\"glyphicon glyphicon-remove\"></span></a>",
    "is_active": true,
    "is_guest": false,
    "nid": "2853",
    "image_small": "<img class=\"player-image-24\" src=\"/it/mycoach/season/3636/players/2853/image/player-small\">",
    "stats_minuti": 90,
    "stats_played": 3,
    "stats_titolare": 3,
    "stats_benches": 0,
    "stats_goals": 1,
    "stats_assists": 0,
    "stats_ycards": 0,
    "stats_rcards": 0,
    "stats_sostituzione_in": 0,
    "stats_sostituzione_out": 0,
    "stats_training_sessions": 3,
    "stats_training_sessions_percent": "1.0%",
    "stats_injuries": 0,
    "stats_avg_vote": "-",
    "stats_avg_vote_matches_num": 0,
    "link": "/it/mycoach/season/3636/players/2853",
    "assist_positions_scored": [
      [
        {
          "x": 59,
          "y": 12,
          "title": "<span class=\"tooltip-goal\">Prima Squadra 1 - A.C. Milan 0<span class=\"match-event-small-notes\">25/10/2020 10:10</span><div class=\"match-event-item-content\"><span class=\"match-event-player-name\">Christían dell&amp;#039;Abbiáti Abbiati (12')</span><span class=\"match-event-small-notes\">Assist: DieGo LopeX</span></div></span>",
          "eventid": "4402"
        },
        {
          "x": 15,
          "y": 17,
          "title": "<span class=\"tooltip-goal\">Prima Squadra 1 - A.C. Milan 0<span class=\"match-event-small-notes\">25/10/2020 10:10</span><div class=\"match-event-item-content\"><span class=\"match-event-player-name\">Christían dell&amp;#039;Abbiáti Abbiati (12')</span><span class=\"match-event-small-notes\">Assist: DieGo LopeX</span></div></span>",
          "eventid": "4402"
        }
      ]
    ],
    "assist_positions_assisted": [],
    "goal_positions": [
      {
        "x": 80,
        "y": 17,
        "title": "<span class=\"tooltip-goal\">Prima Squadra 1 - A.C. Milan 0<span class=\"match-event-small-notes\">25/10/2020 10:10</span><div class=\"match-event-item-content\"><span class=\"match-event-player-name\">Christían dell&amp;#039;Abbiáti Abbiati (12')</span><span class=\"match-event-small-notes\">Assist: DieGo LopeX</span></div></span>",
        "color": "blue",
        "eventid": "4402"
      }
    ],
    "training_stats": {
      "trainings_number": 3,
      "trainings_total_duration": 303,
      "trainings_total_duration2": 315,
      "trainings_avg_duration": 101,
      "fasi": {
        "Attivazione": 126,
        "Finale": 72,
        "Situazionale": 58,
        "Gioco": 27,
        "Analitica": 20
      },
      "goals": [
        {
          "name": "Situazioni",
          "time": 90,
          "punteggio": 10
        },
        {
          "name": "Possesso palla",
          "time": 48,
          "punteggio": 10
        },
        {
          "name": "2 contro 2",
          "time": 48,
          "punteggio": 5
        },
        {
          "name": "Dribbling di spalle",
          "time": 42,
          "punteggio": 5
        },
        {
          "name": "Transizioni",
          "time": 24,
          "punteggio": 10
        },
        {
          "name": "Marcamento",
          "time": 24,
          "punteggio": 10
        },
        {
          "name": "Cross",
          "time": 24,
          "punteggio": 5
        },
        {
          "name": "3 contro 1",
          "time": 15,
          "punteggio": 5
        }
      ],
      "training_goals_quality": "80",
      "sessions": {
        "2018-10-03 15:00": {
          "quality": "89",
          "difficulty": "1.3"
        },
        "2018-09-30 21:30": {
          "quality": "88",
          "difficulty": "1.0"
        },
        "2017-07-05 10:00": {
          "quality": "50",
          "difficulty": "2.0"
        }
      },
      "difficulty_avg": "1.4",
      "mezzi": {
        "Esercizio": 132,
        "Esercizio Situazionale": 48,
        "Gioco Semplificato": 96,
        "Gioco condizionato": "24",
        "Situazione Semplificata": "15"
      },
      "tipologie": {
        "Tecnica": {
          "sigla": "TE",
          "durata": 42
        },
        "Tattica": {
          "sigla": "TA",
          "durata": 183
        },
        "Preparazione Atletica": {
          "sigla": "PA",
          "durata": 0
        },
        "Tecnica del portiere": {
          "sigla": "TP",
          "durata": 0
        },
        "": {
          "sigla": null,
          "durata": "90"
        }
      },
      "cycle_goals": [
        "Modulo 4-2-3-1",
        "Possesso palla",
        "Reazione",
        "Resistenza alla velocità",
        "Tattica individuale in fase di non possesso palla",
        "Presa di posizione",
        "Pressione",
        "Marcamento",
        "Situazioni",
        "Tattica individuale in fase di possesso palla",
        "Transizioni",
        "resistenza",
        "Forza",
        "Rapidità",
        "Difesa della porta",
        "Small sided game",
        "Sovrapposizione",
        "Calci piazzati"
      ]
    },
    "matches_stats": {
      "2020-12-14 14:00": {
        "minuti": 0,
        "titolare": true,
        "goal": 0,
        "injury": 0,
        "yellow_card": 0,
        "red_card": 0,
        "yellow_card_minuto": "-",
        "red_card_minuto": "-",
        "sostituzione_in": 0,
        "sostituzione_out": 0,
        "sostituzione_out_minuto": "-",
        "sostituzione_in_minuto": "-",
        "assists": 0,
        "assist_positions_scored": [],
        "assist_positions_assisted": [],
        "goal_positions": [],
        "match_info": {
          "type": "Campionato Serie A Tim",
          "timestamp": "2020-12-14 14:00",
          "date": "14/12/2020 14:00",
          "date_small": "14/12",
          "home_match": true,
          "duration": 0,
          "status": "<span class=\"match-status match-status-3\">Pareggiata</span>",
          "home_team": "<span>Prima Squadra</span>",
          "away_team": "<span>TEST AVVERSARIO</span>",
          "has_evaluation": false,
          "our_goals": 0,
          "others_goals": 0,
          "link": "/it/mycoach/season/3636/matches/4709",
          "result": "0:0"
        },
        "vote": 0
      },
      "2020-10-25 10:30": {
        "minuti": 90,
        "titolare": true,
        "goal": 1,
        "injury": 0,
        "yellow_card": 0,
        "red_card": 0,
        "yellow_card_minuto": "-",
        "red_card_minuto": "-",
        "sostituzione_in": 0,
        "sostituzione_out": 0,
        "sostituzione_out_minuto": "-",
        "sostituzione_in_minuto": "-",
        "assists": 0,
        "assist_positions_scored": [
          [
            {
              "x": 59,
              "y": 12,
              "title": "<span class=\"tooltip-goal\">Prima Squadra 1 - A.C. Milan 0<span class=\"match-event-small-notes\">25/10/2020 10:10</span><div class=\"match-event-item-content\"><span class=\"match-event-player-name\">Christían dell&amp;#039;Abbiáti Abbiati (12')</span><span class=\"match-event-small-notes\">Assist: DieGo LopeX</span></div></span>",
              "eventid": "4402"
            },
            {
              "x": 15,
              "y": 17,
              "title": "<span class=\"tooltip-goal\">Prima Squadra 1 - A.C. Milan 0<span class=\"match-event-small-notes\">25/10/2020 10:10</span><div class=\"match-event-item-content\"><span class=\"match-event-player-name\">Christían dell&amp;#039;Abbiáti Abbiati (12')</span><span class=\"match-event-small-notes\">Assist: DieGo LopeX</span></div></span>",
              "eventid": "4402"
            }
          ]
        ],
        "assist_positions_assisted": [],
        "goal_positions": [
          {
            "x": 80,
            "y": 17,
            "title": "<span class=\"tooltip-goal\">Prima Squadra 1 - A.C. Milan 0<span class=\"match-event-small-notes\">25/10/2020 10:10</span><div class=\"match-event-item-content\"><span class=\"match-event-player-name\">Christían dell&amp;#039;Abbiáti Abbiati (12')</span><span class=\"match-event-small-notes\">Assist: DieGo LopeX</span></div></span>",
            "color": "blue",
            "eventid": "4402"
          }
        ],
        "goal_minuto": "12'",
        "match_info": {
          "type": "Campionato Serie A Tim",
          "timestamp": "2020-10-25 10:30",
          "date": "25/10/2020 10:30",
          "date_small": "25/10",
          "home_match": true,
          "duration": 90,
          "status": "<span class=\"match-status match-status-1\">Vinta</span>",
          "home_team": "<b><span>Prima Squadra</span></b>",
          "away_team": "<span>A.C. Milan</span>",
          "has_evaluation": false,
          "our_goals": 1,
          "others_goals": 0,
          "link": "/it/mycoach/season/3636/matches/4399",
          "result": "1:0"
        },
        "vote": 0
      },
      "2017-11-19 21:00": {
        "minuti": 0,
        "titolare": true,
        "goal": 0,
        "injury": 0,
        "yellow_card": 0,
        "red_card": 0,
        "yellow_card_minuto": "-",
        "red_card_minuto": "-",
        "sostituzione_in": 0,
        "sostituzione_out": 0,
        "sostituzione_out_minuto": "-",
        "sostituzione_in_minuto": "-",
        "assists": 0,
        "assist_positions_scored": [],
        "assist_positions_assisted": [],
        "goal_positions": [],
        "match_info": {
          "type": "Campionato",
          "timestamp": "2017-11-19 21:00",
          "date": "19/11/2017 21:00",
          "date_small": "19/11",
          "home_match": true,
          "duration": 0,
          "status": "<span class=\"match-status match-status-3\">Pareggiata</span>",
          "home_team": "<span>Prima Squadra</span>",
          "away_team": "<span>MILAN</span>",
          "has_evaluation": false,
          "our_goals": 0,
          "others_goals": 0,
          "link": "/it/mycoach/season/3636/matches/4080",
          "result": "0:0"
        },
        "vote": 0
      }
    }
  },
  "attendance": {
    "3782": {
      "2853": {
        "attendance": "AE",
        "notes": "Some random note 745653653"
      }
    },
    "3784": {
      "2853": {
        "attendance": "AE",
        "notes": ""
      }
    },
    "3785": {
      "2853": {
        "attendance": "PP",
        "notes": "Some random note 707000254"
      }
    },
    "3786": {
      "2853": {
        "attendance": "AE",
        "notes": ""
      }
    },
    "3787": {
      "2853": {
        "attendance": "AE",
        "notes": ""
      }
    },
    "3789": {
      "2853": {
        "attendance": "AE",
        "notes": ""
      }
    },
    "3790": {
      "2853": {
        "attendance": "AE",
        "notes": ""
      }
    }
  }
                } }/>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the stats.
                  </p>
                  <p>
                    Attendance season start represent the first Monday of this season. Season end is always equals to season start and can be ignored.
                  </p>
                  <p>Info about minutes trained for each phase can be found inside <i>player/training_stats/fasi</i> which is an object like:</p>
                  <CodeHighlighter code={ {
                    "Attivazione": 126,
                    "Finale": 72,
                    "Situazionale": 58,
                    "Gioco": 27,
                    "Analitica": 20
                  } }/>
                  <p>Attendance to an event is marked with one of the codes in the tables. Codes starting with P are presences, codes starting with A are Absences.<br/></p>
                  <b>PRESENT</b>
                  <table className="table table-striped table-bordered table-hover">
                    <tbody>
                      <tr><th style={{ width: '1%' }}>Code</th><th>Meaning</th></tr>
                      <tr><td style={{ width: '1%' }}>PP</td><td>Present</td></tr>
                      <tr><td style={{ width: '1%' }}>PT</td><td>Tardy</td></tr>
                      <tr><td style={{ width: '1%' }}>PA</td><td>Anticipated Exit</td></tr>
                    </tbody>
                  </table>

                  <b>ABSENT</b>
                  <table className="table table-striped table-bordered table-hover">
                    <tbody>
                      <tr><th style={{ width: '1%' }}>Code</th><th>Meaning</th></tr>
                      <tr><td style={{ width: '1%' }}>AE</td><td>Excused Absense</td></tr>
                      <tr><td style={{ width: '1%' }}>AU</td><td>Unexcused Absence</td></tr>
                      <tr><td style={{ width: '1%' }}>AI</td><td>Injured</td></tr>
                      <tr><td style={{ width: '1%' }}>AL</td><td>Illness</td></tr>
                      <tr><td style={{ width: '1%' }}>AM</td><td>Medical Examination</td></tr>
                      <tr><td style={{ width: '1%' }}>AC</td><td>Call in Other Team</td></tr>
                      <tr><td style={{ width: '1%' }}>AT</td><td>Selected Team</td></tr>
                      <tr><td style={{ width: '1%' }}>AA</td><td>Audition</td></tr>
                      <tr><td style={{ width: '1%' }}>AS</td><td>School</td></tr>
                      <tr><td style={{ width: '1%' }}>AF</td><td>Family</td></tr>
                      <tr><td style={{ width: '1%' }}>AJ</td><td>Job</td></tr>
                      <tr><td style={{ width: '1%' }}>AP</td><td>Permission</td></tr>
                      <tr><td style={{ width: '1%' }}>AN</td><td>Player Not Available</td></tr>
                      <tr><td style={{ width: '1%' }}>AO</td><td>Other</td></tr>
                    
                    </tbody>
                  </table>
                  
            </div>
        );
  }


} 