
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from '../reducers/index';
import watcherSaga from '../sagas/index';


const initialiseSagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeWithDevTools( applyMiddleware(initialiseSagaMiddleware) )
);

initialiseSagaMiddleware.run(watcherSaga);

console.log("Store created and saga initialized...");

export default store;