

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "season/%sid/training-session/%tid/drill-details/%nid/image";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'tid', type: 'numeric',  mandatory: true, default: '', description: 'Training Session ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Drill ID'},
]

const serviceSample = {}


export function TrainingSessionDrillDetailsImage() {
  return <TrainingSessionDrillDetailsImageComponent/>;
}


class TrainingSessionDrillDetailsImageComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>View Image of a drill used in the specific Training Session</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to get the image of a generic drill. This call does not work with Custom drills sub images.<br/>
                    The image returned is actually the cover for the drill and the size cannot be specified.<br/>
                    The service can be used by players.
                  </p>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={false}
                        usePayload={false}
                        returnImage={true}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with a single attribute called data, containing a Base64 encode image data URL (like data:image/;base64,....) if the image is found, otherwise 404.
                  </p>

            </div>
        );
  }


} 