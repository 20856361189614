

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { ArgumentsTable } from '../components/ArgumentsTable';
import { CodeHighlighter } from '../components/CodeHighlighter';
// import { CodeHighlighter } from '../components/CodeHighlighter';


const seasonCreateEndpoint = "season/new";
const seasonCreateArguments = [
  { name: 'title', type: 'string',  mandatory: true, default: '', description: 'The name of the Season'},
  { name: 'category', type: 'string',  mandatory: true, default: '', description: 'The category of the season. Use items "categories"'},
  { name: 'image',     type: 'string',  mandatory: false, default: '', description: 'Base64 version of an image (PNG or JPG). Season image should not be bigger than 1024px each side.'},
  { name: 'season_start',     type: 'date',  mandatory: true, default: '', description: 'Start of Season using format yyyy-mm-dd hh:ii:ss'},
  { name: 'season_end',     type: 'date',  mandatory: true, default: '', description: 'End of Season using format yyyy-mm-dd hh:ii:ss'},
  { name: 'num_players',  type: 'integer',  mandatory: true, default: '', description: 'Number of players at disposal during the training'},
  { name: 'match_day',  type: 'integer',  mandatory: true, default: '', description: 'Match day (1=Monday, 7=Sunday)'},
  { name: 'training_days',  type: 'integer[]',  mandatory: false, default: '', description: 'Array of days (1=Monday, 7=Sunday)'},
  { name: 'training_duration',  type: 'integer',  mandatory: true, default: '', description: 'Average duration of the training session. User 120 if unsure.'},
  { name: 'fixed_numbers',  type: 'boolean',  mandatory: false, default: false, description: 'Indicates if we should use fixed numbers for the players.'},
]

const seasonCreateUrlArguments = [
]

const seasonCreateSample = {
  'title' : 'Kids 2020/2021',
  'category' : '44',
  'image' : '...base64 encoded image....',
  'season_start' : '2020-07-01 12:00:00',
  'season_end'   : '2021-06-30 12:00:00',
  'num_players' : 18,
  'match_day'   : 7,
  'training_days' : [2,4],
  'training_duration': 120,
  'fixed_numbers': false
}


export function SeasonCreate() {
  return <SeasonCreateComponent/>;
}


class SeasonCreateComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Create a new Season</h3>

                  <p>Endpoint: <b>{seasonCreateEndpoint}</b></p>

                  <p>
                    This service can be used to create a season.<br/>
                    Please note that ONLY CLUB OWNERS are allowed to create a season, while users
                    with the proper permission are allowed to modify the season settings.<br/>
                    Based on the subscription, the creation of new seasons may not be allowed.
                    A proper error message would be returned in this case.
                    <br/>
                  </p>
                  
                  <ServiceForm
                        args={seasonCreateArguments}
                        urlArgs={seasonCreateUrlArguments}
                        sample={seasonCreateSample}
                        endpoint={seasonCreateEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with the season data
                  </p>
                  <ArgumentsTable args={seasonCreateArguments} />
                  
                  <h4>Response</h4>
                  <p>
                    The created season as JSON or a list of validation errors indexed by field:
                  </p>
                    <CodeHighlighter code={ {
  "errors": {
    "title": "Field title is required",
    "category": "Invalid value provided for field category.",
    "num_players": "Invalid value provided for field num_players.",
    "match_day": "Invalid value provided for field match_day.",
    "training_days": "Invalid value provided for field training_days."
  }
}
                  }/>

                  
                  
            </div>
        );
  }


} 