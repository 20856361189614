

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const playerImageEndpoint = "season/%sid/player/%pid/image";

const playerImageArguments = []

const playerImageUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'pid', type: 'numeric',  mandatory: true, default: '', description: 'Player ID'},
]

const playerImageSample = {}


export function PlayerImage() {
  return <PlayerImageComponent/>;
}


class PlayerImageComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Player Image</h3>

                  <p>Endpoint: <b>{renderEndpoint(playerImageEndpoint)}</b></p>

                  <p>
                    This service can be used to get the image of a player. The image returned is encoded in base64
                  </p>
                  
                  <ServiceForm
                        args={playerImageArguments}
                        urlArgs={playerImageUrlArguments}
                        sample={playerImageSample}
                        endpoint={playerImageEndpoint}
                        authRequired={true}
                        usePayload={false}
                        returnImage={true}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with a single attribute called data, containing a Base64 encode image data URL (like data:image/;base64,....) if the image is found, otherwise 404.
                  </p>

            </div>
        );
  }


} 