
const DO_AUTHENTICATION = "DO_AUTHENTICATION";
const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
const AUTHENTICATION_FAIL = "AUTHENTICATION_FAIL";
const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
const UPDATE_USER_PASS = "UPDATE_USER_PASS";
const UPDATE_URL = "UPDATE_URL";
const LOGOUT = "LOGOUT";

export function authenticate(succCb, errorCb) {
  return { type: DO_AUTHENTICATION, success: succCb, error: errorCb };
};

export function logout() {
  return { type: LOGOUT };
};

export function authenticationSuccess(authToken, refreshToken) {
  return { type: AUTHENTICATION_SUCCESS, authToken, refreshToken };
};

export function refreshTokenSuccess(authToken) {
  return { type: REFRESH_TOKEN_SUCCESS, authToken };
};

export function authenticationFail(error) {
  return { type: AUTHENTICATION_FAIL, error };
};

export function updateUserPassword(username, password) {
  return { type: UPDATE_USER_PASS, username, password };
};


export function updateUrl(baseUrl) {
  return { type: UPDATE_URL, baseUrl };
};


export const Actions = {
  DO_AUTHENTICATION,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAIL,
  UPDATE_USER_PASS,
  UPDATE_URL,
  LOGOUT
};
