

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "season/%sid/training-sessions";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
]

const serviceSample = {}


export function TrainingSessions() {
  return <TrainingSessionsComponent/>;
}


class TrainingSessionsComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Training Sessions</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the list of training sessions of a specific season for which the user is either the owner or a staff member.<br/>
                    An example training session JSON in the returned array:
                  </p>
                  <CodeHighlighter code={ {
                      "nid": "4078",
                      "sid": "3636",
                      "date": "29/06/2018 10:00",
                      "datetime_timestamp": 1530259200,
                      "cancelled": "0",
                      "lastupdate": "27/05/2018 23:46:41",
                      "lastupdate_timestamp": "1527457601",
                      "author": "Giulio Toffoli",
                      "approval_index": 0,
                      "approval_status": "Bozza",
                      "completed": false,
                      "can_edit": true,
                      "can_delete": true,
                      "avgDifficulty": 2
                    }
                  }/>


                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of service.
                  </p>
                  
            </div>
        );
  }


} 