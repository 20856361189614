

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "ebook/%nid/details";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'nid', type: 'text',  mandatory: true, default: '', description: 'Node ID of the ebook product display'},
]

const serviceSample = {}


export function EBookDetails() {
  return <EBookDetailsComponent/>;
}


class EBookDetailsComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Get details about an EBook</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the details about a specific eBook.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={false}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON representation of the ebook product.
                  </p>
                  
            </div>
        );
  }


} 