

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { ArgumentsTable } from '../components/ArgumentsTable';
// import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "user/password-reset";
const serviceArguments = [
  { name: 'email', type: 'text',  mandatory: true, default: null, description: 'User email'}
];

const serviceUrlArguments = [];

const serviceSample = {
  'email'        : 'test_ycapi@saysource.com'
}


export function UserResetPassword() {
  return <UserResetPasswordComponent/>;
}


class UserResetPasswordComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>User Register</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to reset the password. An email will be set to the user with further instructions.
                    <br/>
                    There are limits in place (user_pass_reset_user_window and user_pass_reset_user_limit)
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={false}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with the result or an error object
                  </p>
                  <ArgumentsTable args={serviceArguments} />
                  
                  <h4>Response</h4>
                  <p>
                    A json object with the following two possible keys:<br/>
                    <b>errors</b>: map of errors indexed by field<br/>
                    <b>message</b>: Further instructions have been sent to your e-mail address.
                  </p>
                  
            </div>
        );
  }
} 