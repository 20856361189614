
import { Actions } from '../actions'; 
import { credentialsStatePart } from './credentials';

const initialState = {
  authToken: null,
  refreshToken: null,
  username: credentialsStatePart.username,
  password: credentialsStatePart.password,
  user: null,
  baseUrl: credentialsStatePart.baseUrl,
  authenticating: false
};

function rootReducer(state = initialState, action) {
  if (action.type === Actions.DO_AUTHENTICATION) {
      console.log("Root reducer: Authenticating...", action);
      return { ...state, authenticating: true };
  }
  else if (action.type === Actions.AUTHENTICATION_FAIL) {
    return { ...state, authenticating: false, authToken: null, refreshToken: null };
  }
  else if (action.type === Actions.AUTHENTICATION_SUCCESS) {
    console.log("Autho code: ", action.authToken);
    return { ...state, authenticating: false, authToken: action.authToken, refreshToken: action.refreshToken };
  }
  else if (action.type === Actions.UPDATE_USER_PASS) {
    return { ...state, username: action.username, password: action.password, authToken: null, refreshToken: null };
  }
  else if (action.type === Actions.UPDATE_URL) {
    return { ...state, baseUrl: action.baseUrl };
  }
  else if (action.type === Actions.LOGOUT) {
    return { ...state, authToken: null, refreshToken: null };
  }

  return state;
};

export default rootReducer;