

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';
import { renderEndpoint } from '../utils';


const endpoint = "article/%nid/content";

const args = []
const urlArgs = [
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Article ID'},
]

export const sample = {}


export function Article() {
  return <ArticleComponent/>;
}


class ArticleComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Load an Article</h3>

                  <p>Endpoint: <b>{renderEndpoint(endpoint)}</b></p>

                  <p>
                    This service can be used to get the content of an article and returns a JSON like this one:
                  </p>
                  <CodeHighlighter code={{
                              "nid": "2495",
                              "title": "Possesso palla - Le 4 zone!",
                              "date": "2015-03-12",
                              "img_url": "https://dev.youcoach.it/sites/default/files/cover.png",
                              "premium": false,
                              "requires_subscription": false,
                              "limited": false,
                              "freemium_limit_reached": false,
                              "content": "<!DOCTYPE html><html><head>\n<style>...</body>\n</html>"
                            }} />

                  <ServiceForm
                        args={args}
                        urlArgs={urlArgs}
                        sample={sample}
                        endpoint={endpoint}
                        authRequired={false}
                        usePayload={false}
                        useIframe={true}
                  />

                  <h4>Request</h4>
                  <p>
                    Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the article content
                  </p>

            </div>
        );
  }
  
} 