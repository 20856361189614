

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const seasonImageEndpoint = "season/%sid/image";

const seasonImageArguments = []

const seasonImageUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
]

const seasonImageSample = {}


export function SeasonImage() {
  return <SeasonImageComponent/>;
}


class SeasonImageComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Season Image</h3>

                  <p>Endpoint: <b>{renderEndpoint(seasonImageEndpoint)}</b></p>

                  <p>
                    This service can be used to get the image of a season. Anyway, in general is better to display the image of the Club.
                  </p>

                  <ServiceForm
                        args={seasonImageArguments}
                        urlArgs={seasonImageUrlArguments}
                        sample={seasonImageSample}
                        endpoint={seasonImageEndpoint}
                        authRequired={true}
                        usePayload={false}
                        returnImage={true}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with a single attribute called data, containing a Base64 encode image data URL (like data:image/;base64,....) if the image is found, otherwise 404.
                  </p>

            </div>
        );
  }


} 