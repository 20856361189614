

import React from 'react';
import { ArgumentsTable } from '../components/ArgumentsTable';
import ServiceForm from '../components/ServiceForm';


const matchOpponentPlayerCreateEndpoint = "season/%sid/match/%mid/edit-opponent-player/new";
const matchOpponentPlayerCreateArguments = [
  { name: 'lastname', type: 'string',  mandatory: false, default: '', description: 'Player lastname'},
  { name: 'firstname',  type: 'string',  mandatory: false, default: '', description: 'Player firstname'},
  { name: 'primary_role_id',  type: 'string',  mandatory: false, default: '', description: 'One of the role id (see player roles)'},
  { name: 'birthyear',  type: 'integer',  mandatory: false, default: '', description: 'Birth year'},
  { name: 'captain',   type: 'boolean', mandatory: false, default: false, description: 'If true, the player is set as captain'},
  { name: 'vice_captain',   type: 'boolean', mandatory: false, default: false, description: 'If true, the player is set as vice captain'},
];

const matchOpponentPlayerCreateUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'mid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
]

const matchOpponentPlayerCreateSample = {
  'lastname': 'Cannavaro',
  'firstname': 'Fabio',
  'primary_role_id': '3',
  'birthyear': 1973,
  'captain': true
}


export function MatchOpponentPlayerCreate() {
  return <MatchOpponentPlayerCreateComponent/>;
}


class MatchOpponentPlayerCreateComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Create a new Opponent player</h3>

                  <p>Endpoint: <b>{matchOpponentPlayerCreateEndpoint}</b></p>

                  <p>
                    This service can be used to create a new opponent player.<br/>
                    Lastname and Firstname cannot be both empty, please specify a value for at least one of the two.<br/>
                    <br/>
                    When a new player is create, a new id is always generated server side.<br/>
                    <br/>
                    Please note that additional fields specified in the json are stored as part of the player object, but are not garanteed to be kept in case of player change with the web app.
                  </p>
                  
                  <ServiceForm
                        args={matchOpponentPlayerCreateArguments}
                        urlArgs={matchOpponentPlayerCreateUrlArguments}
                        sample={matchOpponentPlayerCreateSample}
                        endpoint={matchOpponentPlayerCreateEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with the event data
                  </p>
                  <ArgumentsTable args={matchOpponentPlayerCreateArguments} />
                  
                  <h4>Response</h4>
                  <p>
                    The whole opponent formation
                  </p>
                  
            </div>
        );
  }


} 