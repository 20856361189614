

import React from 'react';
import { ArgumentsTable } from '../components/ArgumentsTable';
import ServiceForm from '../components/ServiceForm';


const matchEventEditEndpoint = "season/%sid/match/%mid/edit-event/%eid";
const matchEventEditArguments = [
  { name: 'event_type', type: 'string',  mandatory: true, default: '', description: 'Event type (possible values: "goal","substitution", "yellow_card", "red_card", "injury")'},
  { name: 'opponent',  type: 'boolean',  mandatory: false, default: false, description: 'It specifies if the event is related to our team or the opposite team.'},
  { name: 'minutes',  type: 'integer',  mandatory: false, default: 0, description: 'If 0, the minutes is considered null, all events start at minute 1. Attention! There is not a strict control in respect to the halfs/time parts specified for the match.'},
  { name: 'during_injury_time',  type: 'boolean',  mandatory: false, default: '', description: 'It specifies if the minutes must be considered in the injury time. Example a goal at 46\' could be a 45+1 of the first half (scored during the injury time of the first half), or at minute 1 of the second half, which starts at 46\' of the match.'},
  { name: 'player_id', type: 'integer',  mandatory: false, default: '', description: 'The id of the player subject of the event (valid values depend by the opponent parameter. It is not always required, i.e. when the event is about an autogoal'},
  { name: 'notes',  type: 'string',  mandatory: false, default: '', description: 'Optional notes'},
  { name: 'auto',   type: 'boolean', mandatory: false, default: false, description: '"goal" event specific. If set to true, it indicates that the goal was an autogoal.'},
  { name: 'penalty',   type: 'boolean', mandatory: false, default: false, description: '"goal" event specific. If set to true, it indicates that the goal was scored during the special penalty time. Minutes in this case is ignored.'},
  { name: 'as_penalty',   type: 'boolean', mandatory: false, default: false, description: '"goal" event specific. If set to true, it indicates that the goal was scored as penalty.'},
  { name: 'as_corner',   type: 'boolean', mandatory: false, default: false, description: '"goal" event specific. If set to true, it indicates that the goal action started with a corner.'},
  { name: 'directly_from_freekick',   type: 'boolean', mandatory: false, default: false, description: '"goal" event specific. If set to true, it indicates that the goal was scored directly from a freekick.'},
  { name: 'directly_from_corner',   type: 'boolean', mandatory: false, default: false, description: '"goal" event specific. If set to true, it indicates that the goal was scored directly from the corner.'},
  { name: 'goal_position',   type: 'string', mandatory: false, default: '', description: '"goal" event specific. A stringyfied JSON object with the coordinates {x,y} of where the ball entered the goal (i.e. "{\\"x\\":50,\\"y\\":50}" ). Acceptable value for x and y are in the range [0,100]'},
  { name: 'assist_position',   type: 'string', mandatory: false, default: '', description: '"goal" event specific. A stringyfied JSON array with two points coordinates [{x,y},{x,y}] representing the position of the assist player (player_id2) and the player who scored (player_id) (i.e. [{\\"x\\":60,\\"y\\":17},{\\"x\\":39,\\"y\\":36}] ). Acceptable value for x are in the range [0, 100] and for y are in the range [0, 75] on a view where the x represent the shorter side of the field.'},
  { name: 'player_id2', type: 'integer',  mandatory: false, default: '', description: '"goal" event specific. The id of the player who performed the assist (if any); "substitution" event specific. The id of the player with who player_id has been substituted'},
]

const matchEventEditUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'mid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
  { name: 'eid', type: 'numeric',  mandatory: true, default: '', description: 'Event ID'},
]

const matchEventEditSample = {
  'event_type': 'goal',
  'opponent': false,
  'minutes': 47,
  'during_injury_time': true,
  'player_id': 2853,
  'as_corner': true,
  'goal_position': '{"x":92,"y":7}',
  'assist_position': '[{"x":67,"y":10},{"x":0,"y":0}]',
  'player_id2': 2855
}


export function MatchEventEdit() {
  return <MatchEventEditComponent/>;
}


class MatchEventEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Create a new Match</h3>

                  <p>Endpoint: <b>{matchEventEditEndpoint}</b></p>

                  <p>
                    This service can be used to modify a Match Event. It works pretty much just like the create method.<br/>
                    <br/>
                  </p>
                  
                  <ServiceForm
                        args={matchEventEditArguments}
                        urlArgs={matchEventEditUrlArguments}
                        sample={matchEventEditSample}
                        endpoint={matchEventEditEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with the event data
                  </p>
                  <ArgumentsTable args={matchEventEditArguments} />
                  
                  <h4>Response</h4>
                  <p>
                    The modified match event as JSON
                  </p>
                  
            </div>
        );
  }


} 