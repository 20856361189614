

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const matchEditEndpoint = "season/%sid/match/%nid/edit";
const matchEditArguments = [
  { name: 'date',     type: 'date',  mandatory: true, default: '', description: 'Match date using format yyyy-mm-dd hh:ii:ss'},
  { name: 'home_game',  type: 'boolean',  mandatory: true, default: '', description: 'It specifies if the match is an home game or not'},
  { name: 'mode',  type: 'string',  mandatory: true, default: '', description: 'Game mode, see the modes table'},
  { name: 'opponent', type: 'string',  mandatory: true, default: '', description: 'The name of the opponent team'},
  { name: 'type',  type: 'integer',  mandatory: false, default: '', description: 'Optional type of game, see game types table'},
  { name: 'type_name',  type: 'string', mandatory: false, default: '', description: 'Name of the championship or other game name'},
  { name: 'number',  type: 'string', mandatory: false, default: '', description: 'Optional serial number in the context of the championship'},
  { name: 'num_players',  type: 'integer', mandatory: false, default: '', description: 'Number of players (usually 5, 7 or 11)'},
  { name: 'city', type: 'string',  mandatory: true, default: '', description: 'City of the match'},
  { name: 'location', type: 'string',  mandatory: true, default: '', description: 'The match location'},
  { name: 'address', type: 'string',  mandatory: true, default: '', description: 'The match address'},
  { name: 'country', type: 'string',  mandatory: true, default: '', description: 'ISO two letters country code'},
  { name: 'formation', type: 'array',  mandatory: false, default: '', description: 'If a formation array is provided, it will be saved. See MatchSetPlayers for details on the formation array'}
]

const matchEditUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
]

const matchEditSample = {
  'date'        : '2020-10-25 10:30:00',
  'home_game'   : true,
  'mode'        : 'goals',
  'opponent'    : 'A.C. Milan',
  'type'        : '1',
  'type_name'   : 'Serie A Tim',
  'number'      : '1',
  'num_players' : 11,
  'city'        : 'Padova',
  'location'    : 'Stadio Euganeo',
  'address'     : 'Viale Nereo Rocco, 60',
  'country'     : 'IT' 
}


export function MatchEdit() {
  return <MatchEditComponent/>;
}


class MatchEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Edit a Match</h3>

                  <p>Endpoint: <b>{matchEditEndpoint}</b></p>

                  <p>
                    This service can be used to create a new Match.<br/>
                    <br/>
                  </p>
                  
                  <ServiceForm
                        args={matchEditArguments}
                        urlArgs={matchEditUrlArguments}
                        sample={matchEditSample}
                        endpoint={matchEditEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with the session data
                  </p>
                  <p>
                    See <b>MatchCreate</b> for details.
                  </p>


                  <h4>Response</h4>
                  <p>
                    A JSON with the list of matchEdit.
                  </p>
                  
            </div>
        );
  }


} 