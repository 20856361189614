

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/training-session/%nid";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Training Session ID'},
]

const serviceSample = {}


export function TrainingSessionDetails() {
  return <TrainingSessionDetailsComponent/>;
}


class TrainingSessionDetailsComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>View a Training Session</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the detailed info about a Training Session.<br/>
                    Each track has its own duration, but in general the duration of the training session is considered to be the duration of the Main Track,
                    which is marked as default track.<br/>
                    To get drill images, consider to use the Generic Drill Image service (keeping in mind the overhead of getting the image as base64 data),
                    otherwise you would have to differentiate between custom drills (and use the Custom Drill Image service), and not-custom drills, for
                    which you can get the image directly from the specified URL in binary format.<br/>
                    <br/>
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the training session details.
                  </p>
                  <p>
                  </p>
                  
            </div>
        );
  }


} 