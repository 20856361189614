
import React from 'react';

export const PrettyJsonProps = {
  mainStyle: 'line-height:1.3;color:black;background-color:#f7f7f7CC;padding:20px;border-radius:10px;overflow:auto;border: 1px solid #ececec',
  errorStyle: 'line-height:1.3;color:#66d9ef;overflow:auto;',
  keyStyle: 'color:#905;',
  stringStyle: 'color:#690;',
  valueStyle: 'color:#08d037;',
  booleanStyle: 'color:#21a03f;',
}


/**
 * Ecrypt a string and encode the binary data in base64url 
 * @param {*} key 
 * @param {*} str 
 */
export function encryptRC4(key, str) {
  const data = rc4(key, str);
  return encodeBase64(data);
}


/**
 * Decode a base64url string and then decrypt the binary data.
 * @param {*} key 
 * @param {*} data 
 */
export function decryptRC4(key, data) {
  const str = decodeBase64(data);
  return rc4(key, str);
}


/*
 * RC4 symmetric cipher encryption/decryption
 *
 * @license Public Domain
 * @param string key - secret key for encryption/decryption
 * @param string str - string to be encrypted/decrypted
 * @return string
 */
function rc4(key, str) {
	var s = [], j = 0, x, res = '';
	for (var i = 0; i < 256; i++) {
		s[i] = i;
	}
	for (i = 0; i < 256; i++) {
		j = (j + s[i] + key.charCodeAt(i % key.length)) % 256;
		x = s[i];
		s[i] = s[j];
		s[j] = x;
	}
	i = 0;
	j = 0;
	for (var y = 0; y < str.length; y++) {
		i = (i + 1) % 256;
		j = (j + s[i]) % 256;
		x = s[i];
		s[i] = s[j];
		s[j] = x;
		res += String.fromCharCode(str.charCodeAt(y) ^ s[(s[i] + s[j]) % 256]);
	}
	return res;
}



var b64array = "ABCDEFGHIJKLMNOP" +
           "QRSTUVWXYZabcdef" +
           "ghijklmnopqrstuv" +
           "wxyz0123456789+/" +
           "=";

function encodeBase64(input) {

    let base64 = "";
    let chr1, chr2, chr3 = "";
    let enc1, enc2, enc3, enc4 = "";
    let i = 0;

    do {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);
    
        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;
    
        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }

        base64  = base64  +
            b64array.charAt(enc1) +
            b64array.charAt(enc2) +
            b64array.charAt(enc3) +
            b64array.charAt(enc4);
        chr1 = chr2 = chr3 = "";
        enc1 = enc2 = enc3 = enc4 = "";
    } while (i < input.length);

    // Replace characters for Base64url
    return base64.replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');

}

function decodeBase64(input) {

    // Replace characters for Base64url
    input = (input + '==='.slice((input.length + 3) % 4))
              .replace(/-/g, '+')
              .replace(/_/g, '/');

    let output = "";
    let chr1, chr2, chr3 = "";
    let enc1, enc2, enc3, enc4 = "";
    let i = 0;

    do {
        enc1 = b64array.indexOf(input.charAt(i++));
        enc2 = b64array.indexOf(input.charAt(i++));
        enc3 = b64array.indexOf(input.charAt(i++));
        enc4 = b64array.indexOf(input.charAt(i++));
        
        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;
        
        output = output + String.fromCharCode(chr1);
        
        if (enc3 !== 64) {
            output = output + String.fromCharCode(chr2);
        }
        if (enc4 !== 64) {
            output = output + String.fromCharCode(chr3);
        }
    
        chr1 = chr2 = chr3 = "";
        enc1 = enc2 = enc3 = enc4 = "";
    
    } while (i < input.length);

    return output;
}

export const renderEndpoint = (str) => {

    const parts = str.split('/');

    const res = parts.reduce( (acc, p, i) => {

        if (i > 0) {
            acc.push(<span key={i + '_'}>/</span>);
        }

        if (p.startsWith('%')) {
             acc.push(<span key={i} className="urlparam">{p}</span>);
        }
        else {
            acc.push(<span key={i}>{p}</span>);
        }
        return acc;
    }, []);

    return res;
}