

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const clubEndpoint = "club";

const clubArguments = []

const clubUrlArguments = [
]

const clubSample = {}


export function Club() {
  return <ClubComponent/>;
}


class ClubComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Club Info</h3>

                  <p>Endpoint: <b>{renderEndpoint(clubEndpoint)}</b></p>

                  <p>
                    This service can be used to get the info about the Club OWNED by the user. This is not a way to get the info about a generic club, but
                    a way to get the club definition of which the user is the owner. If the user is not a club owner, or in case a club was never defined,
                    the method returns 404 not found.<br/>
                    The JSON returned contains the club label and image.
                  </p>

                  <ServiceForm
                        args={clubArguments}
                        urlArgs={clubUrlArguments}
                        sample={clubSample}
                        endpoint={clubEndpoint}
                        authRequired={true}
                        usePayload={false}
                        returnImage={true}
                        returnImageExtractor={ data => typeof data !== 'undefined' ? data.image : null }
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the club ID, the club label and an image attribute, containing a Base64 encode image data URL (like data:image/;base64,....) if the image is actually available.
                  </p>

            </div>
        );
  }


} 