

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "articles/list-column/%column_id?page=%page";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'column_id', type: 'numeric',  mandatory: true, default: '', description: 'One of the column_id returned from the latest articles service'},
  { name: 'page', type: 'numeric',  mandatory: false, default: '1', description: 'The list is paginated, use page get argument to specify a page'},
]

const serviceSample = {}


export function ColumnArticlesList() {
  return <ColumnArticlesListComponent/>;
}


class ColumnArticlesListComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>List Articles for a specific Column.</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the (paginated) list of article teasers for a specific column. It returns an array of articles.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={false}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with an array of article teasers.
                  </p>
                  
            </div>
        );
  }


} 