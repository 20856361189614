


/**
 * Avoid to commit this file...
 */
export const credentialsStatePart = {
  username: process.env.REACT_APP_API_USERNAME,
  password: process.env.REACT_APP_API_PASSWORD,
  baseUrl: process.env.REACT_APP_API_URL
};