import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "generic-drill/%nid";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Generic Drill ID'},
]

const serviceSample = {}


export function GenericDrill() {
  return <GenericDrillComponent/>;
}


class GenericDrillComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Generic Drill</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the a Generic Drill. It works also with custom drills.<br/>
                    Not all types of drill node type is guaranteed to provide all the fields.<br/> 
                    An example of a Generic Drill:
                  </p>
                  <CodeHighlighter code={ {
                    "nid": "1379",
                    "type": "esercizio_progressione",
                    "title": "6 contro 6 gol sulla porta e su 2 porticine esterne",
                    "obiettivo_primario": "Ampiezza",
                    "obiettivi_secondari": "Tiro in porta, Difesa della porta, Cambio gioco",
                    "descrizione": "",
                    "materiale": "&lt;ul&gt;<br />\r\n\t&lt;li style=&quot;text-align: justify;&quot;&gt;Cinesini per delimitare l&amp;rsquo;area di gioco&lt;/li&gt;<br />\r\n\t&lt;li style=&quot;text-align: justify;&quot;&gt;6 casacche rosse&lt;/li&gt;<br />\r\n\t&lt;li style=&quot;text-align: justify;&quot;&gt;2 porte regolamentari&lt;/li&gt;<br />\r\n\t&lt;li style=&quot;text-align: justify;&quot;&gt;8 coni&lt;/li&gt;<br />\r\n\t&lt;li style=&quot;text-align: justify;&quot;&gt;Alcuni palloni&lt;/li&gt;<br />\r\n&lt;/ul&gt;<br />\r\n",
                    "preparazione": "&lt;ul&gt;<br />\r\n\t&lt;li style=&quot;text-align: justify;&quot;&gt;Area di gioco: 50 x 40 metri&lt;/li&gt;<br />\r\n\t&lt;li style=&quot;text-align: justify;&quot;&gt;Giocatori: 12 + 2 portieri&lt;/li&gt;<br />\r\n\t&lt;li style=&quot;text-align: justify;&quot;&gt;Tempo di svolgimento: 14&amp;nbsp;minuti&lt;/li&gt;<br />\r\n\t&lt;li style=&quot;text-align: justify;&quot;&gt;Numero di serie: 2 da 5 minuti con 2 minuti di recupero tra le serie&lt;/li&gt;<br />\r\n&lt;/ul&gt;<br />\r\n",
                    "regole": "&lt;ul&gt;<br />\r\n\t&lt;li style=&quot;text-align: justify;&quot;&gt;Le squadre devono cercare di realizzare una rete nella porta regolamentare o nelle porticine avversarie&lt;/li&gt;<br />\r\n\t&lt;li style=&quot;text-align: justify;&quot;&gt;Il gol nelle porte regolamentari può essere segnato solo di testa o al volo, mentre il gol nelle porticine esterne può essere segnato in qualunque modo&lt;/li&gt;<br />\r\n&lt;/ul&gt;<br />\r\n&lt;p style=&quot;text-align: justify;&quot;&gt;Nell&amp;rsquo;esempio un possibile sviluppo di gioco che porta al gol di testa nella porta grande la squadra in maglia bianca.&lt;/p&gt;<br />\r\n&lt;p style=&quot;text-align: justify;&quot;&gt;&amp;nbsp;&lt;/p&gt;<br />\r\n&lt;p style=&quot;text-align: center;&quot;&gt;&lt;img alt=&quot;&quot; src=&quot;/sites/default/files/ssg8b_0.jpg&quot; style=&quot;width: 550px; height: 413px;&quot; /&gt;&lt;/p&gt;<br />\r\n&lt;p style=&quot;text-align: center;&quot;&gt;&amp;nbsp;&lt;/p&gt;<br />\r\n&lt;p style=&quot;text-align: center;&quot;&gt;&lt;img alt=&quot;&quot; src=&quot;/sites/default/files/ssg8c_0.jpg&quot; style=&quot;width: 550px; height: 413px;&quot; /&gt;&lt;/p&gt;<br />\r\n",
                    "varianti": "&lt;ol&gt;<br />\r\n\t&lt;li style=&quot;text-align: justify;&quot;&gt;Limitazione dei tocchi&lt;/li&gt;<br />\r\n\t&lt;li style=&quot;text-align: justify;&quot;&gt;Obbligo di effettuare un certo numero di passaggi prestabilito prima di poter segnare una rete&lt;/li&gt;<br />\r\n&lt;/ol&gt;<br />\r\n",
                    "temi_allenatore": "",
                    "giocatori": "14",
                    "durata": "14",
                    "difficolta": "3",
                    "porte": "0",
                    "mezzo": "Gioco condizionato",
                    "tipologia": "Tattica",
                    "tipologia_sigla": "TA",
                    "vcategorie": []
                  }}/>


                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the Custom Drills.
                  </p>
                  
            </div>
        );
  }


} 