

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "list-favorites/details";

const serviceArguments = [];

const serviceUrlArguments = [];

const serviceSample = {}


export function ListFavoritesDetails() {
  return <ListFavoritesDetailsComponent/>;
}


class ListFavoritesDetailsComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>List favorite articles (with titles)</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to list the articles' nids and titles in the user's favorites list.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of current favorite nodes.
                  </p>

            </div>
        );
  }


} 