

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const endpoint = "mobile-api-version";

const args = []

export const sample = {}


export function IOSFullVersion() {
  return <IOSFullVersionComponent/>;
}


class IOSFullVersionComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>IOS Full Version</h3>

                  <p>Endpoint: <b>{renderEndpoint(endpoint)}</b></p>

                  <p>
                    The service returns the last version that has been fully approved by Apple and can show the
                    additional content not allowed during the approval process.
                  </p>

                  <ServiceForm
                        args={args}
                        sample={sample}
                        endpoint={endpoint}
                        authRequired={false}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the last full version.
                  </p>

            </div>
        );
  }
  
} 