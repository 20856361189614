import { Actions, authenticationSuccess, authenticationFail } from '../actions/index';
import { takeEvery, call, put, select } from "redux-saga/effects";
import Axios from 'axios';

import { resetAPI } from '../API';


export default function* watcherSaga() {
  console.log("Run watcherSaga");
  yield takeEvery(Actions.DO_AUTHENTICATION, authenticationSaga);
}

function* authenticationSaga(action) {
  try {
    // Get all the info from the store...
    const state = yield select(state => state);

    const baseUrl = state.baseUrl;
    const username = state.username;
    const password = state.password;

    resetAPI();
    console.log("Authenticating with.... ", baseUrl, username, password);
    const res = yield call(authenticate, baseUrl, username, password);
    console.log(res);
    yield put(authenticationSuccess(res.data.token, res.data.refresh_token));
    if (typeof action.success === 'function') {
      action.success();
    }
  } catch (err) {
      yield put(authenticationFail(err.response));
      if (typeof action.error=== 'function') {
        console.log("Invoking call back", err);
        action.error(err.response);
      }
  }
}

function authenticate(baseUrl, username, password) {
  return Axios.post(baseUrl + 'login', {
    username,
    password
  });
}
