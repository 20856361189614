

import React from 'react';
import { CodeHighlighter } from '../components/CodeHighlighter';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/player/%nid/invite";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Player ID'},
]

const serviceSample = {}


export function PlayerInvite() {
  return <PlayerInviteComponent/>;
}


class PlayerInviteComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Invite a Player to access the app</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to invite a player to collaborate with YouCoach App.<br/>
                    In order to invite a player, the fields Firstname, Lastname and email must be present in the player profile.<br/>
                    If an account with the player's email is present, and that email is not used by another player,
                    the account is bound to the player and a formal invitation to collaborate is sent to the player by email.<br/>
                    If no user exists with that email, a new YouCoach account is created and then bound to the player. In this
                    case the email sent to the player also contains the credentials to access YouCoach App.
                  </p>
                                    
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the result.<br/>
                    <strong>Positive result</strong>
                  </p>
                  <CodeHighlighter code={{"result": "ok", "message": "XYZ has been successfully created! An email has been sent to xyz@email.it." }} />
                  <p>
                  <strong>Negative result</strong>
                  </p>
                  <CodeHighlighter code={{"result": "error", "message": "This player has been already invited to access this season." }} />
                  <p>
                  </p>
                  
            </div>
        );
  }


} 