

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';

const serviceEndpoint = "season/%sid/match/%nid/evaluation";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
]

const serviceSample = {}


export function MatchEvaluation() {
  return <MatchEvaluationComponent/>;
}


class MatchEvaluationComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>View a Match Evaluation</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get a match evaluation which has the following structure:<br/>
                  </p>
                  <CodeHighlighter code={{
  "athletic_level": 0,
  "technical_level": 0,
  "tactical_level": 0,
  "temperament_level": 0,
  "collective_performance": "",
  "strengths": "",
  "weaknesses": "",
  "opponent_athletic_level": 0,
  "opponent_technical_level": 0,
  "opponent_tactical_level": 0,
  "opponent_temperament_level": 0,
  "opponent_collective_performance": "",
  "opponent_strengths": "",
  "opponent_weaknesses": "",
  "players": [
    {
      "player_id": "2854",
      "vote": 0,
      "notes": ""
    },
    {
      "player_id": "2856",
      "vote": 0,
      "notes": ""
    }]}} />

                <p>Please note that an empty string or a value 0 indicates usually the absence of that value.<br/></p>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the match evaluation.
                  </p>
                  
            </div>
        );
  }


} 