

import React from 'react';
import ServiceForm from '../components/ServiceForm';

const serviceEndpoint = "season/%sid/match/%nid/evaluation/edit";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
]

const serviceSample = {
  "athletic_level": 6,
  "technical_level": 8,
  "tactical_level": 7,
  "temperament_level": 7,
  "collective_performance": "Still some work to do with the attack, but the team is doing pretty well",
  "strengths": "Fast, technically superior",
  "weaknesses": "Not well coordinated during the offensive phase",
  "opponent_athletic_level": 0,
  "opponent_technical_level": 0,
  "opponent_tactical_level": 0,
  "opponent_temperament_level": 0,
  "opponent_collective_performance": "",
  "opponent_strengths": "",
  "opponent_weaknesses": "",
  "players": [
    {
      "player_id": "2854",
      "vote": 8,
      "notes": "Nice match, the player gave 110%"
    },
    {
      "player_id": "2856",
      "vote": 5,
      "notes": "Bad day for this guy"
    }]};


export function MatchEvaluationEdit() {
  return <MatchEvaluationEditComponent/>;
}


class MatchEvaluationEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Edit a Match Evaluation</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to modify (even just partially) a match evaluation.<br/>
                    athletic_level, technical_level, tactical_level and temperament_level accept values from 0 to 10 included (0 = not set, 1 = very bad, 10 = very good).<br/>
                    Players vote is a number from 0 to 10, and in includes half values (0, 0.5, 1, 1.5, 2, ..., 9.5, 10).
                  </p>

                <p>Please note that an empty string or a value 0 indicates usually the absence of that value, but will be stored as it is passed.<br/></p>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                  A JSON with the evaluation data
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the match evaluation.
                  </p>
                  
            </div>
        );
  }


} 