

import React from 'react';
import { ArgumentsTable } from '../components/ArgumentsTable';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/match/%mid/edit-system/%eid";
const serviceArguments = [
  { name: 'minutes',  type: 'integer',  mandatory: false, default: 0, description: 'A positive integer. Please note that all events start at minute 1. In case of initial system (main), this field is ignored. Attention! There is not a strict control in respect to the halfs/time parts specified for the match.'},
  { name: 'during_injury_time',  type: 'boolean',  mandatory: false, default: '', description: 'It specifies if the minutes must be considered in the injury time. Example a goal at 46\' could be a 45+1 of the first half (scored during the injury time of the first half), or at minute 1 of the second half, which starts at 46\' of the match. In case of initial system (main), this field is ignored.'},
  { name: 'notes',  type: 'string',  mandatory: false, default: '', description: 'Optional notes'},
  { name: 'system', type: 'object', mandatory: true, default: '', description: 'A system object (see the sample) which defines our and their system'}
]

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'mid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
  { name: 'eid', type: '"main" | "new" | numeric',  mandatory: true, default: '', description: 'Event ID or Main system'},
]

const serviceSample = {
  'minutes': 30,
  'during_injury_time': false,
  'notes': 'Technical change',
  'system': {"num_players":"9","systems":["3-4-1","3-1-3-1"],"formationsVisibility":[true,true],"players":[[{"player":"2855","delta":[0,0],"position":0},{"player":"2853","delta":[0,0],"position":1},{"player":"2854","delta":[0,0],"position":2},{"player":"2856","delta":[0,0],"position":3},{"player":"2857","delta":[0,0],"position":4},{"player":"2859","delta":[0,0],"position":5},{"player":"2861","delta":[0,0],"position":6},{"player":"2862","delta":[0,0],"position":7},{"player":"2863","delta":[0,0],"position":8}],[{"player":"1662543793-1","delta":[0,0],"position":0},{"player":"1662543793-2","delta":[0,0],"position":1},{"player":"1662543793-3","delta":[0,0],"position":2},{"player":"1662543793-5","delta":[0,0],"position":3},{"player":"1662544055-6","delta":[0,0],"position":4},{"player":"1662674787-6","delta":[0,0],"position":5},{"player":"1662706209-7","delta":[0,0],"position":6},{"player":"1662712169-8","delta":[0,0],"position":7},{"player":"1662712217-9","delta":[0,0],"position":8}]]} 
}


export function MatchSystem() {
  return <MatchSystemComponent/>;
}


class MatchSystemComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Create or edit the main Match System and system change events</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to:<br/>
                    1. edit the initial system for both our team and opponent team (<i>season/%sid/match/%mid/edit-system/<b>main</b></i>)<br/>
                    2. create a system change event (<i>season/%sid/match/%mid/edit-system/<b>new</b></i>)<br/>
                    2. create edit a system change event (<i>season/%sid/match/%mid/edit-system/<b>123</b></i>)<br/>
                    <br/>
                    The system defines which disposition the team is using (i.e. 4-4-3), optionally which players oppcupy which position in the system and a delta (x,y) to adjust the exact position respect to the default.<br/>
                    The disposition and the players depend by how many players per team are involved (which is saved in the system field "num_players").<br/>
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with the event data
                  </p>
                  <ArgumentsTable args={serviceArguments} />
                  
                  <h4>Response</h4>
                  <p>
                    The created match event as JSON or the match JSON when modifying the initial system (main).
                  </p>
                  
            </div>
        );
  }


} 