

import React from 'react';
import ServiceForm from '../components/ServiceForm';
// import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "season/%sid/generic-event/%nid/delete";
const serviceArguments = [];

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Generic Event ID'},
];

const serviceSample = {}


export function GenericEventDelete() {
  return <GenericEventDeleteComponent/>;
}


class GenericEventDeleteComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Delete a new Generic Event</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to delete a Generic Event.<br/>
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    GET or POST request without payload.
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                  A JSON with the answer  {'{'} result: 'ok' {'}'}  or an HTTP error (403 or 404)
                  </p>
                  
            </div>
        );
  }


} 