

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/training-session/%nid/delete";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Training Session ID'},
]

const serviceSample = {}


export function TrainingSessionDelete() {
  return <TrainingSessionDeleteComponent/>;
}


class TrainingSessionDeleteComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Delete a Training Session</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                  This service can be used to delete a Training Session.<br/>
                  The operation will fail if the user has not the permissions to delete the training session or
                  if the training session was created inside an incubator and the status is approved and the user is not the owner of the training session.<br/>
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                  The service returns ({"{"} result: "ok" {"}"} or an error.
                  </p>
                  <p>
                  </p>
                  
            </div>
        );
  }


} 