

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "season/%sid/training-load/event-data/%nid/edit";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Event ID (can be a match or a training session)'}
]

const serviceSample = {
  "2853": {
    "time": "108",
    "rpe": "1.1",
    "type": 0,
    "notes": "high intensity workout",
    "remove": 0
  }
};


export function TLMEventDataEdit() {
  return <TLMEventDataEditComponent/>;
}


class TLMEventDataEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>TLM Event Data Edit</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to set / modify / delete the RPE and time spent training/playing of one or more player for a specific event.<br/>
                    An object indexed by pid needs to be provided.<br/>
                    For each player RPE and time needs to be set. Optionally it can be set <i>type</i> and <i>notes</i>.<br/>
                    <i>type</i> can be 0 or 1, and is used internally by the application to decide if the TL data for a specific user counts for the entire
                    team statistics (i.e. usually TL of goalkeepers is not kept in the team statistics). Please also note that this value is suggested
                    when getting data for an event and if not specified is set to 0 for goalkeepers and players without a role automatically.<br/>
                    <b>Players not appearing in the JSON are not modified.</b><br/>
                    If the provided time is 0, a default time is looked for by checking the time spent by the specific player in the event or calculating
                    the event full time or, in case of training sessions by using the default training session time.
                  </p>
                  <p>
                  To remove a player's RPE, just set the user's RPE object property "remove" to 1. In this case all the other properties are ignored.<br/>
                  </p>
                  <p>
                    If the service is executed by a Player, only the value belonging to the player (if present) are modified.
                  </p>
                  <p>
                    The service returns the list of RPE for all the players in this event. See TLM Event Data for more details.
                  </p>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                        returnImage={true}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON object indexed by player ID.
                  </p>

            </div>
        );
  }


} 