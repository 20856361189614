

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const matchDetailsEndpoint = "season/%sid/match/%nid";
const matchDetailsArguments = []

const matchDetailsUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
]

const matchDetailsSample = {}


export function MatchDetails() {
  return <MatchDetailsComponent/>;
}


class MatchDetailsComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>View a Match</h3>

                  <p>Endpoint: <b>{matchDetailsEndpoint}</b></p>

                  <p>
                    This service can be used to get the detailed info about a Match.<br/>
                    <br/>
                  </p>
                  
                  <ServiceForm
                        args={matchDetailsArguments}
                        urlArgs={matchDetailsUrlArguments}
                        sample={matchDetailsSample}
                        endpoint={matchDetailsEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the match details.
                  </p>
                    <table className="table table-striped table-bordered table-hover">
                    <tbody><tr>
                        <th>Match status ID</th>
                        <td>
0 = MATCH_STATUS_TO_BE_PLAYED<br/>
1 = MATCH_STATUS_WON<br/>
2 = MATCH_STATUS_LOST<br/>
3 = MATCH_STATUS_DRAWN<br/>
4 = MATCH_STATUS_CANCELED<br/>
5 = MATCH_STATUS_SUSPENDED<br/>
                        </td>
                      </tr>

                      <tr>
                        <th>Duration Types</th>
                        <td>
1 = First Half (Primo Tempo)<br/>
2 = Second Half (Secondo Tempo)<br/>
3 = Third Half (Terzo Tempo)<br/>
4 = Fourth Half (Quarto Tempo)<br/>
5 = First Extra Time (Primo Tempo Supplementare)<br/>
6 = Second Extra Time (Secondo) Tempo Supplementare<br/>
0 = Other (Altro)<br/>
                        </td>
                      </tr>
                      </tbody>                   
                    </table>
                  <p>
                    For other constants, check <b>MatchCreate</b> docs.
                  </p>
                  
            </div>
        );
  }


} 