

import React from 'react';
import { CodeHighlighter } from '../components/CodeHighlighter';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/training-session/%nid/attendance";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Training Session ID'},
]

const serviceSample = {}


export function TrainingSessionAttendance() {
  return <TrainingSessionAttendanceComponent/>;
}


class TrainingSessionAttendanceComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>TrainingSessionAttendance Data for Event</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the attendance for the players on a particular training session<br/>
                  </p>
                  <CodeHighlighter code={{
                      "attendance": {
                        "2853": {
                          "pid": "2853",
                          "nid": "3782",
                          "attendance": "AE",
                          "notes": "Some random note 745653653",
                          "updated_on": "1563311739"
                        },
                        "2854": {
                          "pid": "2854",
                          "nid": "3782",
                          "attendance": "PP",
                          "notes": "",
                          "updated_on": "1563322921"
                        }
                      }
                    }} />

                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the screening details.<br/>
                    The attendace field is one of the following:<br/>
                    <table className="table table-striped table-bordered table-hover">
                      <tbody>
                      <tr style={{ borderBottom: '2px solid black'}}><td>0</td><td colSpan="2">N/D (it should never be used in edito mode, other than avoid saving)</td></tr>

                      <tr><td>PP</td><td>Presente</td><td>Presente</td></tr>
                      <tr><td>PT</td><td>In ritardo</td><td>Presente</td></tr>
                      <tr style={{ borderBottom: '2px solid black'}}><td>PA</td><td>Uscita Anticipata</td><td>Presente</td></tr>

                      <tr><td>AE</td><td>Assenza Giustificata</td><td>Assente</td></tr>
                      <tr><td>AU</td><td>Assenza Non Giustificata</td><td>Assente</td></tr>
                      <tr><td>AI</td><td>Infortunato</td><td>Assente</td></tr>
                      <tr><td>AL</td><td>Malato</td><td>Assente</td></tr>
                      <tr><td>AM</td><td>Visita Medica</td><td>Assente</td></tr>
                      <tr><td>AC</td><td>Convocazione Altra Squadra</td><td>Assente</td></tr>
                      <tr><td>AT</td><td>Rappresentativa</td><td>Assente</td></tr>
                      <tr><td>AA</td><td>Provino</td><td>Assente</td></tr>
                      <tr><td>AS</td><td>Scuola</td><td>Assente</td></tr>
                      <tr><td>AF</td><td>Famiglia</td><td>Assente</td></tr>
                      <tr><td>AJ</td><td>Lavoro</td><td>Assente</td></tr>
                      <tr><td>AP</td><td>Permesso</td><td>Assente</td></tr>
                      <tr><td>AN</td><td>Player Not Available</td><td>Assente</td></tr>
                      <tr><td>AO</td><td>Altro</td><td>Assente</td></tr>

                      </tbody>
                    </table>
                  </p>
                  <p>
                  </p>
                  
            </div>
        );
  }


} 