

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "custom-drills";

const serviceArguments = []

const serviceUrlArguments = [
]

const serviceSample = {}


export function CustomDrills() {
  return <CustomDrillsComponent/>;
}


class CustomDrillsComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Custom Drills</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the list of Custom Drills of the user.<br/>
                    An example generic event JSON in the returned array:
                  </p>
                  <CodeHighlighter code={ {
                          "nid": "3552",
                          "title": "AAAAAAA",
                          "images": [
                            "6421",
                            "6721",
                            "12700",
                            "12701"
                          ],
                          "obiettivo_primario": "<span class=\"primary-goal\">Correre</span>",
                          "obiettivi_secondari": "<span class=\"secondary-goal\">Psicocinetica</span>, <span class=\"secondary-goal\">Tattica individuale in fase di non possesso palla</span>, <span class=\"secondary-goal\">Transizioni</span>",
                          "tags": "<span class=\"tag\">test</span>",
                          "descrizione": "<div class=\"mycoach-text\"><p>Prova di <strong>integrazione</strong> con Youtube at <a href=\"http://www.youtube.com\" rel=\"nofollow\">http://www.youtube.com</a></p>\n<ul>\n<li>Punto 1</li>\n<li>Punto 2</li>\n<li>Punto 3</li>\n</ul>\n<p><strong>Prova</strong> di <em>altro</em> <strike>testo</strike> da inserire.</p>\n</div>",
                          "materiale": "",
                          "preparazione": "",
                          "regole": "",
                          "varianti": "",
                          "temi_allenatore": "<div class=\"mycoach-text\"><p>Questi sono i temi per l'allenatore!!!</p>\n</div>",
                          "giocatori": "2",
                          "durata": "15",
                          "difficolta": "3",
                          "porte": "0",
                          "video": "https://www.youtube-nocookie.com/embed/PAmcDJnsIbQ?rel=0&amp;amp;showinfo=0",
                          "mezzo": "Esercizio",
                          "tipologia": "Tecnica",
                          "tipologia_sigla": "TE",
                          "categorie": "<span class=\"age-category\">Prima squadra</span><span class=\"age-category\">Juniores-Beretti-Primavera</span><span class=\"age-category\">Piccoli amici</span>",
                          "vcategorie": "<span class=\"age-category\">Prima squadra</span><span class=\"age-category\">Juniores-Beretti-Primavera</span><span class=\"age-category\">Piccoli amici</span>",
                          "permessi": "Solo io",
                  }}/>


                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of Custom Drills.
                  </p>
                  
            </div>
        );
  }


} 