

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';


const serviceEndpoint = "club/%cid/image";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'cid', type: 'numeric',  mandatory: true, default: '', description: 'Club ID'},
]

const serviceSample = {}


export function ClubImage() {
  return <ClubImageComponent/>;
}


class ClubImageComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Club Image</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to get the image of a club.<br/>
                  </p>

                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                        returnImage={true}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with a single attribute called data, containing a Base64 encode image data URL (like data:image/;base64,....) if the image is found, otherwise 404 or 401 if no access to at least a season of this club is allowed.
                  </p>

            </div>
        );
  }


} 