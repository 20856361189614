

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "package-drills/%code";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'code', type: 'text',  mandatory: true, default: '', description: 'Package code'},
]

const serviceSample = {}


export function PackageDrills() {
  return <PackageDrillsComponent/>;
}


class PackageDrillsComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Package Drills</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to get the list of Drills available in a specific package.<br/>
                    The list returned contains drill serialized just like Generic Drill Details would do.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                    Empty POST or GET
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON with the list of the Drills in the package.
                  </p>
                  
            </div>
        );
  }


} 