

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { ArgumentsTable } from '../components/ArgumentsTable';
import { CodeHighlighter } from '../components/CodeHighlighter';
// import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "club/edit";
const serviceArguments = [
  { name: 'name', type: 'string',  mandatory: true, default: '', description: 'The name of the Club'},
  { name: 'image',     type: 'string',  mandatory: false, default: '', description: 'Base64 version of an image (PNG or JPG). Season image should not be bigger than 1024px each side.'},
  { name: 'remove_image',   type: 'boolean',  mandatory: false, default: '', description: 'If set to true, the image will be removed from the season'},
]

const serviceUrlArguments = []

const serviceSample = {
  'name' : 'My nice club',
  'remove_image' : true,
}


export function ClubEdit() {
  return <ClubEditComponent/>;
}


class ClubEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Edit (or create) a Club</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to modify the Club info.<br/>
                    Please note that ONLY the club OWNER can modify the club info (name and logo).<br/>
                    If a club was never created before, it will be automatically created.
                    <br/>
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with the club info
                  </p>
                  <ArgumentsTable args={serviceArguments} />
                  
                  <h4>Response</h4>
                  <p>
                    The created Club as JSON or a list of validation errors indexed by field:
                  </p>
                    <CodeHighlighter code={ {
  "errors": {
    "name": "Field name is required",
    }
}
                  }/>

                  
            </div>
        );
  }


} 