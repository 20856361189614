

import React from 'react';
import { ArgumentsTable } from '../components/ArgumentsTable';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/player/%pid/edit";
const serviceArguments = [
  { name: 'firstname',     type: 'string',  mandatory: true,  default: '', description: 'Firstname'},
  { name: 'lastname',      type: 'string',  mandatory: true,  default: '', description: 'Lastname'},
  { name: 'email',         type: 'string',  mandatory: false, default: '', description: 'A valid email address'},
  { name: 'primary_role',  type: 'string',  mandatory: false, default: '', description: 'The primary role (see table)'},
  { name: 'secondary_role',type: 'string',  mandatory: true,  default: '', description: 'The secondary role (see table)'},
  { name: 'birthdate',     type: 'date',    mandatory: false, default: '', description: 'Player\'s birthdate in format yyyy-mm-dd hh:ii:ss'},
  { name: 'birthplace',    type: 'string',  mandatory: true,  default: '', description: 'Player\'s birth place'},
  { name: 'foot_type',     type: 'string',  mandatory: true,  default: '', description: 'Foot Type (see table)'},
  { name: 'nationality',   type: 'string',  mandatory: true,  default: '', description: 'Country ISO alpha-2'},
  { name: 'height',        type: 'numeric', mandatory: false, default: '', description: 'Positive integer'},
  { name: 'num',           type: 'integer', mandatory: false, default: '', description: 'T-Shirt number'},
  { name: 'weight',        type: 'numeric', mandatory: false, default: '', description: 'Player\'s weight'},
  { name: 'federation_id', type: 'string',  mandatory: true,  default: '', description: 'Federation ID'},
  { name: 'address',       type: 'string',  mandatory: true,  default: '', description: 'Address'},
  { name: 'city',          type: 'string',  mandatory: true,  default: '', description: 'City'},
  { name: 'telphone1',     type: 'string',  mandatory: true,  default: '', description: 'Telephone (default)'},
  { name: 'telphone2',     type: 'string',  mandatory: true,  default: '', description: 'Telephone 2'},
  { name: 'telphone3',     type: 'string',  mandatory: true,  default: '', description: 'Telephone 3'},
  { name: 'notes',         type: 'string',  mandatory: true,  default: '', description: 'Optional notes'},
  { name: 'active',        type: 'boolean', mandatory: true,  default: '', description: 'Mark the player as active in the current season'},
  { name: 'guest',         type: 'boolean', mandatory: true,  default: '', description: 'Mark the player as a guest of the current season'},
  { name: 'image',         type: 'string',  mandatory: true,  default: '', description: 'Player image'},
  { name: 'remove_image',  type: 'boolean', mandatory: true,  default: '', description: 'Remove player image (works only in edit mode)'},
  
]

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'pid', type: 'numeric',  mandatory: true, default: '', description: 'Player ID'}
]

const serviceSample = {
  'firstname': 'Giulio',
  'lastname': 'Toffoli',
  'email': 'test@test.it',
  'primary_role': '6R',
  'secondary_role': '6',
  'birthdate': '1978-07-01 12:00:00',
  'birthplace': 'Padova',
  'foot_type': '3',
  'nationality': 'IT',  
  'height': 173,
  'num': '11',
  'weight': 66.5,
  'federation_id': '',
  'address': 'Via T.Aspetti 321',
  'city': 'Padova',
  'telphone1': '123456890',
  'telphone2': '998855221',    
  'telphone3': '123334423',
  'notes': 'This is a note',
  'active': true,
  'guest': false,
  'remove_image': true
}


export function PlayerEdit() {
  return <PlayerEditComponent/>;
}


class PlayerEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Edit a Player</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to edit a player in the context of a specific season<br/>
                    <br/>
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with the player data
                  </p>
                  <ArgumentsTable args={serviceArguments} />
                  
                  <table className="table table-striped table-bordered table-hover">
                  <tbody>
                    <tr><th>foot_type</th><td><div style={{ display: 'inline-block' }}>
                        0 => Non specificato<br/>
                        1 => Piede destro<br/>
                        2 => Piede sinistro<br/>
                        3 => Ambidestro<br/>
                                  </div>
                                </td></tr>

                    <tr><th>primary_role and secondary_role</th><td><div style={{ display: 'inline-block' }}>
                        <b>Soccer 11</b><br/>
                        0 => (No role specified)<br/>
                        1 => Goalkeeper<br/>
                        2 => Defender <br/>
                        3 => Central Defender <br/>
                        4 => Left Full Back <br/>
                        4R => Right Full Back <br/>
                        5 => Sweeper <br/>
                        6 => Left Centre back <br/>
                        6R => Right Centre back <br/>
                        7 => Left Marker <br/>
                        7R => Right Marker <br/>
                        8 => Midfielder <br/>
                        9L => Left Centre midfielder <br/>
                        9 => Right Centre midfielder <br/>
                        10L => Left Winger <br/>
                        10 => Right Winger <br/>
                        11L => Left Inside midfielder <br/>
                        11 => Right Inside midfielder <br/>
                        12 => Half-Back <br/>
                        13L => Left Centre halfback <br/>
                        13 => Right Centre halfback <br/>
                        14 => Striker <br/>
                        15 => Central forward <br/>
                        16 => Left Outside forward <br/>
                        16R => Right Outside forward <br/>
                        17 => Inside forward <br/>
                        18 => Attacking midfielder <br/>
                        19 => Shadow Striker <br/>
                        <br/>
                        <b>Futsal</b><br/>
                        20 => Last man          <br/>
                        21 => Right winger <br/>
                        22 => Left winger <br/>
                        23 => Pivot <br/>
                        24 => Utility Player <br/>
                        25 => Striker <br/>

                                  </div>
                                </td></tr>            
                    </tbody>
                  </table>
                 
                  <h4>Response</h4>
                  <p>
                    The created player as JSON
                  </p>
                  
            </div>
        );
  }


} 