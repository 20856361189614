

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { renderEndpoint } from '../utils';

const serviceEndpoint = "season/%sid/training-load/settings/edit";

const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'}
]

const serviceSample = {
  "hooper_frequency": "1",
  "hooper_days": [
    1,
    2,
    3,
    4,
    5,
    6,
    7
  ],
  "hooper_questions": [
    1,
    2,
    3,
    4,
    5
  ],
  "show_monotony_strain": "0"
}


export function TLMSettingsEdit() {
  return <TLMSettingsEditComponent/>;
}


class TLMSettingsEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>TLM Event Settings Edit</h3>

                  <p>Endpoint: <b>{renderEndpoint(serviceEndpoint)}</b></p>

                  <p>
                    This service can be used to set the TLM Settings.<br/>
                    Check TLMSettingsEdit service for details of the Settings.<br/>
                    The JSON to be sent has the identical format as the one returned by TLMSettingsEdit.<br/>
                    <i>show_monotony_strain</i> is optional.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                        returnImage={false}
                  />

                  <h4>Request</h4>
                  <p>
                    POST
                  </p>
                  <h4>Response</h4>
                  <p>
                    A JSON object with the save settings
                  </p>

            </div>
        );
  }


} 