

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const matchDetailsEndpoint = "season/%sid/match/%nid/delete";
const matchDetailsArguments = []

const matchDetailsUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
]

const matchDetailsSample = {}


export function MatchDelete() {
  return <MatchDeleteComponent/>;
}


class MatchDeleteComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Delete a Match</h3>

                  <p>Endpoint: <b>{matchDetailsEndpoint}</b></p>

                  <p>
                    This service can be used to delete a Match and all its eventes.<br/>
                    <br/>
                  </p>
                  
                  <ServiceForm
                        args={matchDetailsArguments}
                        urlArgs={matchDetailsUrlArguments}
                        sample={matchDetailsSample}
                        endpoint={matchDetailsEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    A JSON with the the object 'result' =&gt; 'ok'
                  </p>
                  
            </div>
        );
  }


} 