

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { ArgumentsTable } from '../components/ArgumentsTable';
// import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "user/register";
const serviceArguments = [
  { name: 'email',     type: 'text',  mandatory: true, default: null, description: 'User email'},
  { name: 'firstname',     type: 'text',  mandatory: true, default: null, description: 'User firstname'},
  { name: 'lastname',     type: 'text',  mandatory: true, default: null, description: 'User lastname'},
  { name: 'password',     type: 'text',  mandatory: true, default: null, description: 'User password, rules may apply'},
  { name: 'sections',     type: 'array of numbers',  mandatory: true, default: null, description: 'Section, see user sections for reference'},
  { name: 'role',     type: 'tnumericext',  mandatory: true, default: null, description: 'Role, see user roles for reference'},
  { name: 'accept_privacy', type: 'boolean',  mandatory: true, default: null, description: 'User must have accepted the pricacy policy'},
  { name: 'optin_newsletter', type: 'boolean',  mandatory: false, default: null, description: 'Opt-in for the newsletter, not mandatory'},
  { name: 'optin_updates', type: 'boolean',  mandatory: false, default: null, description: 'Opt-in to get YouCoach updates by email, not mandatory'}
];

const serviceUrlArguments = [];

const serviceSample = {
  'email'        : 'test_ycapi@saysource.com',
  'firstname'    : 'José',
  'lastname'     : 'Mourinho',
  'password'     : '26gennaio1963',
  'sections'      : [0],
  'role'         : 0,
  'accept_privacy' : true,
  'optin_newsletter' : false,
  'optin_updates' : true
}


export function UserRegister() {
  return <UserRegisterComponent/>;
}


class UserRegisterComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>User Register</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to create a new YouCoach Account to have access, i.e. to premium content.
                    <br/>
                    This service does not activate the device token, which will be enabled with a different service to be used after the user has been created.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={false}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with the user data.<br/>
                    User sections:<br/>
                    <ul>
                    <li>0|Men's soccer</li>
                    <li>1|Women's soccer</li>
                    <li>2|Futsal</li>
                    </ul>

                  </p>
                  <ArgumentsTable args={serviceArguments} />
                  
                  <h4>Response</h4>
                  <p>
                    The created user.
                  </p>
                  
            </div>
        );
  }
} 