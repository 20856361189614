

import React from 'react';
import ServiceForm from '../components/ServiceForm';


const serviceEndpoint = "season/%sid/match/%nid/delete-opponent-player/%pid";
const serviceArguments = []

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Match ID'},
  { name: 'pid', type: 'numeric',  mandatory: true, default: '', description: 'Opponent Player ID'},
]

const serviceSample = {}


export function MatchOpponentPlayerDelete() {
  return <MatchOpponentPlayerDeleteComponent/>;
}


class MatchOpponentPlayerDeleteComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Delete an Opponent Player</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to delete an Opponent Player.<br/>
                    <br/>
                    No adjustments will be done to the system changes that reference this player id.
                    <br/>
                    No error is returned if the player has not been found.
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={false}
                  />

                  <h4>Request</h4>
                  <p>
                  Simple GET or POST request without parameters
                  </p>
                  
                  <h4>Response</h4>
                  <p>
                    The updated whole opponent formation
                  </p>
                  
            </div>
        );
  }


} 