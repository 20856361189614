

import React from 'react';
import ServiceForm from '../components/ServiceForm';
import { ArgumentsTable } from '../components/ArgumentsTable';
// import { CodeHighlighter } from '../components/CodeHighlighter';


const serviceEndpoint = "season/%sid/training-session/%nid/edit";
const serviceArguments = [
  { name: 'date',     type: 'date',  mandatory: false, default: null, description: 'Training session date using format yyyy-mm-dd hh:ii:ss'},
  { name: 'cancelled', type: 'boolean',  mandatory: false, default: null, description: 'Set the training session as cancelled/not cancelled'},
  { name: 'players', type: 'array of player ids',  mandatory: false, default: null, description: 'Set the training session players'}
];

const serviceUrlArguments = [
  { name: 'sid', type: 'numeric',  mandatory: true, default: '', description: 'Season ID'},
  { name: 'nid', type: 'numeric',  mandatory: true, default: '', description: 'Training Session ID'},
];

const serviceSample = {
  'date'        : '2020-10-25 10:35:00',
  'cancelled'   : true,
  'players'     : [1,2,3]
}


export function TrainingSessionEdit() {
  return <TrainingSessionEditComponent/>;
}


class TrainingSessionEditComponent extends React.Component {

  render() {

        return (
            <div>
                  <h3>Create a new Training Session</h3>

                  <p>Endpoint: <b>{serviceEndpoint}</b></p>

                  <p>
                    This service can be used to create a basic Training Session, with no drills.<br/>
                    <br/>
                  </p>
                  
                  <ServiceForm
                        args={serviceArguments}
                        urlArgs={serviceUrlArguments}
                        sample={serviceSample}
                        endpoint={serviceEndpoint}
                        authRequired={true}
                        usePayload={true}
                  />

                  <h4>Request</h4>
                  <p>
                    A JSON with the match data
                  </p>
                  <ArgumentsTable args={serviceArguments} />
                  
                  <h4>Response</h4>
                  <p>
                    The created Training Session as JSON
                  </p>
                  
            </div>
        );
  }


} 